<template>
    <v-container>
      <v-row>
        <v-col md="6" cols="12">
          <v-textarea auto-grow rows="2" label="System prompt" v-model="systemPrompt" variant="outlined"></v-textarea>
          <v-form @submit.prevent="click">
            <OnClickOutside @click="showArea" v-if="!showTextarea">
              <div class="play-area text-left" ref="playArea" contenteditable="true" @input="onInput" @keydown="handleKeydown">
                <span v-html="play"></span>
              </div>
            </OnClickOutside>
            <OnClickOutside v-else @trigger="showTextarea = false">
              <v-textarea ref="area" auto-grow rows="15" variant="outlined" class="mb-0" v-model="fullPrompt"></v-textarea>
            </OnClickOutside>
            <v-btn class="my-3" type="submit" :loading="loading" :disabled="showTextarea">submit</v-btn>
          </v-form>
    
          <v-textarea v-if="gptResponded" v-model="gptResponded" auto-grow variant="outlined"></v-textarea>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col md="6" cols="12">
          <v-select label="Model" variant="outlined" :items="models" v-model="model" item-title="name" item-value="name" return-object></v-select>
    
          <!-- New dropdown for category filtering -->
          <v-select
            label="Category"
            variant="outlined"
            v-model="selectedCategory"
            :items="categories"
          ></v-select>
    
          <div class="d-flex align-center">
            <v-btn class="my-4 mr-2" @click="addSectionDialog = true">add section</v-btn>
            <v-btn class="my-4 mr-2" @click="diffDialog = true">diff</v-btn>
          </div>
    
          <div class="d-flex flex-column bigCol">
            <!-- Loop through filtered configs instead of all configs -->
            <div class="mb-3" v-for="([key]) in filteredConfigs" :key="key">
              <div class="d-flex align-center mb-3">
                <h3 class="text-left">{{ key.split(': ')[1] }}</h3>
                <v-chip class="ml-2" size="small" :color="key.startsWith('Questionnaires:') ? 'primary' : 'secondary'">
                  {{ key.split(':')[0] }}
                </v-chip>
                <v-btn small color="success" class="ml-2" :loading="fieldLoading[key]" @click="saveField(key)">
                  Save
                </v-btn>
              </div>
              <v-textarea v-model="configs[key]" label="Prompt" auto-grow variant="outlined"></v-textarea>
              <v-divider></v-divider>
            </div>
          </div>
        </v-col>
      </v-row>
    
      <v-dialog v-model="addSectionDialog" width="400">
        <v-card>
          <div class="d-flex flex-column pa-10">
            <h3 class="mb-3">new section</h3>
            <v-text-field label="Title" variant="outlined" v-model="newSectionTitle"></v-text-field>
            <v-textarea label="Prompt" variant="outlined" v-model="newSectionPrompt"></v-textarea>
            <v-btn @click="addSection">submit</v-btn>
          </div>
        </v-card>
      </v-dialog>
    
      <v-dialog v-model="diffDialog">
        <v-card>
          <div class="d-flex flex-column pa-10">
            <h3 class="mb-3">Difference</h3>
            <v-select v-model="diffOne" :items="configsArray" item-title="title" item-value="content"></v-select>
            <v-select v-model="diffTwo" :items="configsArray" item-title="title" item-value="content"></v-select>
            <span v-for="(part, index) in diff" :key="index" :class="part.added ? 'added' : part.removed ? 'removed' : 'unchanged'">
              {{ part.value }}
            </span>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  
  

  <script setup>
  import { ref, onMounted, computed, nextTick, watch } from 'vue'
  import { db } from '@/firebase.js'
  import { OnClickOutside } from '@vueuse/components'
  import { diffWords } from 'diff'
  import axios from 'axios'
  
  const area = ref(null)
  const showTextarea = ref(false)
  const fullPrompt = ref('')
  const systemPrompt = ref('You are an AI assistant that helps summarize doctor-patient conversations.')
  const loading = ref(false)
  const gptResponded = ref('')
  const playArea = ref(null)
  
  // Loading object for each field
  const fieldLoading = ref({})
  
  // Sample models array
  const models = [
    { name: 'gpt4', endpoint: 'https://mdhub-east2.openai.azure.com/openai/deployments/mdhub-gpt4-32k/chat/completions', version: '2024-02-15-preview' },
    { name: 'gpt4-mini', endpoint: 'https://gpt-4-32-east-us.openai.azure.com/openai/deployments/gpt-4-0-mini-eastUs-450/chat/completions', version: '2024-02-15-preview' },
    { name: 'gpt4-turbo-wsus', endpoint: 'https://mdhub-westus.openai.azure.com/openai/deployments/gpt4-turbo/chat/completions', version: '2024-02-15-preview' },
    { name: 'gpt4o-west-us-3', endpoint: 'https://gpt4-turbo-200q.openai.azure.com/openai/deployments/gpt4o-westus3/chat/completions', version: '2024-02-15-preview' }
  ]
  const model = ref(models[3])
  
  // Configs for different categories
  const configs = ref(null)
  const psychiatryConfigs = ref(null)
  const questionnaireConfigs = ref(null)
  let levelOfDetailConfigs = {} // We'll build this from the subcollection
  
  onMounted(async () => {
    // Fetch psychiatry and questionnaires documents
    const [psychiatryDoc, questionnairesDoc] = await Promise.all([
      db.collection('prompt-config').doc('psychiatry').get(),
      db.collection('prompt-config').doc('questionnaires').get()
    ])
    psychiatryConfigs.value = psychiatryDoc.data()
    questionnaireConfigs.value = questionnairesDoc.data()
  
    // Remove createdAt if it exists
    if (psychiatryConfigs.value) delete psychiatryConfigs.value['createdAt']
    if (questionnaireConfigs.value) delete questionnaireConfigs.value['createdAt']
  
    // Fetch the "section-detail-level" subcollection from "custom-prompts"
    const sectionDetailLevelSnapshot = await db.collection('prompt-config')
      .doc('custom-prompts')
      .collection('section-detail-level')
      .get()
    sectionDetailLevelSnapshot.forEach((doc) => {
      // Each document (e.g., "concise", "standard", "detailed") is expected to have a "prompt" field.
      levelOfDetailConfigs[doc.id] = doc.data()
    })
  
    // Combine all configurations with category prefixes
    configs.value = {
      ...Object.keys(psychiatryConfigs.value || {}).reduce((acc, key) => {
        acc[`Psychiatry: ${key}`] = psychiatryConfigs.value[key]
        return acc
      }, {}),
      ...Object.keys(questionnaireConfigs.value || {}).reduce((acc, key) => {
        acc[`Questionnaires: ${key}`] = questionnaireConfigs.value[key]
        return acc
      }, {}),
      ...Object.keys(levelOfDetailConfigs).reduce((acc, key) => {
        acc[`Level of Detail: ${key}`] = levelOfDetailConfigs[key].prompt
        return acc
      }, {})
    }
  })
  
  const configsArray = computed(() => {
    return Object.entries(configs?.value || {}).map(([title, content]) => ({ title, content }))
  })
  
  const tags = computed(() => {
    let re = {}
    for (const key in configs.value) {
      if (configs.value[key]) {
        let formattedKey = `@${key.replace(/\s+/g, '-').toLowerCase()}`
        formattedKey = formattedKey.replace(/-+/g, '-')
        re[formattedKey] = configs.value[key]
      }
    }
    return re
  })
  
  watch(showTextarea, (val) => {
    nextTick(() => {
      if (!val) {
        const splitText = fullPrompt.value.split('')
        const fullTextAgain = []
        let word = ''
        let insideAtWord = false
  
        splitText.forEach((char) => {
          if (insideAtWord) {
            if (char === ' ' || char === '\n' || char === '.') {
              if (Object.keys(tags.value).includes(word)) {
                fullTextAgain.push(`<span class="highlight">${word}</span>`)
              } else {
                fullTextAgain.push(word)
              }
              fullTextAgain.push(char)
              insideAtWord = false
              word = ''
            } else {
              word += char
            }
          } else if (char === '@') {
            insideAtWord = true
            word = '@'
          } else {
            fullTextAgain.push(char)
          }
        })
  
        if (insideAtWord && Object.keys(tags.value).includes(word)) {
          fullTextAgain.push(`<span class="highlight">${word}</span>`)
        } else {
          fullTextAgain.push(word)
        }
  
        const resultString = fullTextAgain.join('')
        playArea.value.innerHTML = resultString
      }
    })
  })
  
  const click = async () => {
    loading.value = true
    let content = playArea.value.innerHTML
  
    content = content.replace(/<span[^>]*>(@[^<]*)<\/span>/g, (match, tag) => {
      return tags.value[tag] || tag
    })
  
    const messages = [
      { role: 'user', content: content },
      { role: 'system', content: systemPrompt.value }
    ]
  
    try {
      const response = await callGpt(model.value, messages)
      gptResponded.value = response.content
    } catch (error) {
      gptResponded.value = error.message
      console.error(error)
    } finally {
      loading.value = false
    }
  }
  
  function prepareGptParameters(messages, tools) {
    let params = {
      messages,
      temperature: 0.0,
      top_p: 0.95,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 3000,
      stop: null
    }
    if (tools) {
      params.tools = tools
    }
    return params
  }
  
  const callGpt = async (model, messages, tools) => {
    let apiKey = model.key
    let endpoint = model.endpoint
    let apiVersion = model.version
  
    const headers = {
      'api-key': apiKey,
      'Content-Type': 'application/json'
    }
  
    const gptParameters = prepareGptParameters(messages, tools)
  
    try {
      let res = await axios.post(endpoint, gptParameters, {
        headers: headers,
        params: { 'api-version': apiVersion }
      })
  
      const gptResponseMessage = res.data.choices[0].message
      const gptResponseContent = gptResponseMessage.content
      const gptResponseFunctions = gptResponseMessage.tool_calls
      const gptFunctions = []
  
      if (gptResponseFunctions !== undefined && gptResponseFunctions.length !== 0) {
        gptResponseFunctions.forEach((tool) => {
          const toolFunction = tool.function
          if (toolFunction !== undefined) {
            gptFunctions.push({
              id: tool.id,
              name: toolFunction.name,
              parameters: toolFunction.arguments
            })
          }
        })
      }
  
      if (gptFunctions.length === 0) {
        return { content: gptResponseContent }
      } else {
        return { content: '', functions: gptFunctions }
      }
    } catch (error) {
      console.error(error)
    }
  }
  
  // Category filtering and alphabetical sorting
  const selectedCategory = ref("All")
  const categories = ["All", "Psychiatry", "Questionnaires", "Level of Detail"]
  
  const filteredConfigs = computed(() => {
    const entries = Object.entries(configs.value || {})
    let filtered = entries
    if (selectedCategory.value !== "All") {
      filtered = entries.filter(([key]) => key.startsWith(selectedCategory.value + ":"))
    }
    return filtered.sort(([keyA], [keyB]) => {
      const titleA = keyA.includes(": ") ? keyA.split(": ")[1] : keyA
      const titleB = keyB.includes(": ") ? keyB.split(": ")[1] : keyB
      return titleA.localeCompare(titleB)
    })
  })
  
  
  const addSectionDialog = ref(false)
  const newSectionTitle = ref('')
  const newSectionPrompt = ref('')
  
  const addSection = async () => {
    if (!newSectionTitle.value || !newSectionPrompt.value) {
      return
    }
    configs.value[newSectionTitle.value] = newSectionPrompt.value
    newSectionTitle.value = ''
    newSectionPrompt.value = ''
    addSectionDialog.value = false
  }
  
  const diffDialog = ref(false)
  const diffOne = ref('')
  const diffTwo = ref('')
  const diff = computed(() => diffWords(diffOne.value, diffTwo.value))
  
  // Save a single field based on its key, with logs for saving.
  const saveField = async (key) => {
    console.log("Saving field for key:", key, "with value:", configs.value[key])
    fieldLoading.value[key] = true
    try {
      const value = configs.value[key]
      let fieldKey
      if (key.startsWith('Questionnaires:')) {
        fieldKey = key.replace('Questionnaires: ', '')
        console.log("Updating Questionnaires document field:", fieldKey)
        await db.collection('prompt-config').doc('questionnaires').update({
          [fieldKey]: value
        })
      } else if (key.startsWith('Psychiatry:')) {
        fieldKey = key.replace('Psychiatry: ', '')
        console.log("Updating Psychiatry document field:", fieldKey)
        await db.collection('prompt-config').doc('psychiatry').update({
          [fieldKey]: value
        })
      } else if (key.startsWith('Level of Detail:')) {
        fieldKey = key.replace('Level of Detail: ', '')
        console.log("Updating Level of Detail in subcollection for:", fieldKey)
        await db.collection('prompt-config')
          .doc('custom-prompts')
          .collection('section-detail-level')
          .doc(fieldKey)
          .update({ prompt: value })
      }
    } catch (error) {
      console.error('Error saving field', key, error)
    } finally {
      fieldLoading.value[key] = false
    }
  }
  </script>
  

  


<style scoped>
.play-area {
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    padding: 16px;
    min-height: 400px;
    overflow-y: auto;
    white-space: pre-wrap;
    outline: none;
}

::v-deep .highlight {
    color: lightblue !important;
    text-decoration: underline;
}

.bigCol {
    max-height: 80vh;
    overflow-y: auto;
}

.added {
    background-color: #e6ffed;
    color: #24292e;
}

.removed {
    background-color: #ffeef0;
    color: #24292e;
}

.unchanged {
    color: #24292e;
}
</style>