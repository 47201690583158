<template>
    <v-card class="mt-4">
        <v-card-title class="d-flex align-center">
            <span>Conversations</span>
            <v-spacer></v-spacer>
            <v-btn icon="mdi-refresh" variant="text" @click="refreshConversations" :loading="refreshing" class="mr-2"
                title="Refresh conversations"></v-btn>
            <!-- <v-text-field v-model="search" append-inner-icon="mdi-magnify" label="Search" single-line hide-details
                density="compact" class="max-w-xs"></v-text-field> -->
            <v-text-field v-model="search" append-inner-icon="mdi-magnify"
                :append-icon="search ? 'mdi-close' : undefined" @click:append="search = ''" label="Search" single-line
                hide-details density="compact" class="max-w-xs"></v-text-field>
        </v-card-title>

        <v-card-text>
            <v-data-table v-if="!loading || conversations.length > 0" :headers="headers" :items="filteredConversations"
                item-value="callSid" show-expand class="conversations-table">
                <template #[`item.type`]="{ item }">
                    <v-tooltip :text="item.type">
                        <template #activator="{ props }">
                            <v-icon v-bind="props">{{ getPhoneCallTypeIcon(item.type || 'incoming') }}</v-icon>
                        </template>
                    </v-tooltip>
                </template>
                <template #[`item.callStatus`]="{ item }">
                    <v-tooltip :text="item.callStatus">
                        <template #activator="{ props }">
                            <v-icon v-bind="props" v-if="item.callStatus"
                                :color="getStatusColor(item.callStatus)">mdi-phone</v-icon>
                            <span v-else>-</span>
                        </template>
                    </v-tooltip>
                </template>
                <template #[`item.summary_action`]="{ item }">
                    <v-tooltip text="Show Summary">
                        <template #activator="{ props }">
                            <v-btn size="small" variant="text" icon="mdi-text-box-outline" v-bind="props"
                                @click.stop="openSummary(item)"></v-btn>
                        </template>
                    </v-tooltip>
                </template>
                <template #[`item.interactions_action`]="{ item }">
                    <v-tooltip text="View Interactions">
                        <template #activator="{ props }">
                            <v-btn size="small" variant="text" icon="mdi-chat-outline" color="info" v-bind="props"
                                @click.stop="viewConversation(item)"></v-btn>
                        </template>
                    </v-tooltip>
                </template>
                <template #[`item.end_action`]="{ item }">
                    <v-btn size="small" variant="text" color="warning" @click.stop="updateEndTime(item.id)"
                        :disabled="!!item.endedAt" :loading="item.updatingEnd">
                        End Now
                    </v-btn>
                </template>
                <template #[`item.recording`]="{ item }">
                    <audio controls preload="none" :id="item.recordingSid" v-if="item.recordingSid">
                        <source :src="playRecording(item.recordingSid)" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </template>
                <template #[`item.startedAt`]="{ item }">
                    {{ formatDate(item.startedAt) }}
                </template>
                <template #[`item.endedAt`]="{ item }">
                    {{ formatDate(item.endedAt) }}
                </template>
                <template #[`item.duration`]="{ item }">
                    {{ calculateDuration(item.startedAt, item.endedAt) }}
                </template>
                <template #[`item.phoneNumber`]="{ item }">
                    {{ item.type === 'outgoing' ? item.toNumber : item.phoneNumber }}
                </template>
                <template #[`item.callSid`]="{ item }">
                    <div class="d-flex align-center">
                        <v-tooltip :text="item.callSid">
                            <template #activator="{ props }">
                                <span v-bind="props" class="text-truncate d-inline-block" style="max-width: 120px;">
                                    {{ item.callSid }}
                                </span>
                            </template>
                        </v-tooltip>
                        <v-tooltip text="Copy SID">
                            <template #activator="{ props }">
                                <v-btn v-bind="props" icon="mdi-content-copy" size="x-small" variant="text"
                                    density="compact" @click.stop="copyToClipboard(item.callSid)"></v-btn>
                            </template>
                        </v-tooltip>
                    </div>
                </template>
                <template #[`item.data-table-expand`]="{ internalItem, isExpanded, toggleExpand }">
                    <v-btn :append-icon="isExpanded(internalItem) ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                        :text="isExpanded(internalItem) ? 'Collapse' : getSuccessButtonText(internalItem.raw.id)"
                        :color="isExpanded(internalItem) ? 'medium-emphasis' : getSuccessButtonColor(internalItem.raw.id)"
                        class="text-none" size="small" variant="text" border slim @click="() => {
                            toggleExpand(internalItem);
                        }"></v-btn>
                </template>
                <template #expanded-row="{ columns, item }">
                    <tr>
                        <td :colspan="columns.length" class="py-2">
                            <v-sheet rounded="lg" border>
                                <div class="pa-4">
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <div class="text-subtitle-2 font-weight-bold mb-2">Identifier</div>
                                            <div class="mb-4">{{ item.identifier || 'N/A' }}</div>

                                            <div class="text-subtitle-2 font-weight-bold mb-2">Request Type</div>
                                            <div class="mb-4">{{ item.requestType || 'N/A' }}</div>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <div class="text-subtitle-2 font-weight-bold mb-2">Phone Number</div>
                                            <div class="mb-4">{{ item.type === 'outgoing' ? item.toNumber :
                                                item.phoneNumber || 'N/A' }}</div>

                                            <div class="text-subtitle-2 font-weight-bold mb-2">Tasks</div>
                                            <div class="mb-4">{{ item.tasks || 'N/A' }}</div>
                                        </v-col>
                                    </v-row>

                                    <!-- Evaluation Section -->
                                    <v-divider class="my-4"></v-divider>
                                    <div class="text-h6 mb-4">Call Evaluation</div>

                                    <div v-if="loadingEvaluation && !evaluations[item.id]"
                                        class="d-flex justify-center my-4">
                                        <v-progress-circular indeterminate></v-progress-circular>
                                    </div>

                                    <div v-else-if="evaluations[item.id]" class="evaluation-container">
                                        <!-- Debug button -->
                                        <v-btn size="small" variant="text" color="primary" class="mb-2"
                                            @click="triggerEvaluation(item.id)" :loading="item.triggeringEvaluation">
                                            Re-trigger Evaluation
                                        </v-btn>

                                        <div class="d-flex align-center mb-4">
                                            <div class="text-h6 mr-2">Overall Score:</div>
                                            <v-chip :color="getScoreColor(getOverallScore(item.id))" size="large"
                                                class="font-weight-bold">
                                                {{ getOverallScore(item.id)?.toFixed(1) || 'N/A' }}
                                            </v-chip>
                                        </div>

                                        <v-expansion-panels variant="accordion">
                                            <!-- Caller Emotional State -->
                                            <v-expansion-panel
                                                v-if="getEvaluationCategory(item.id, 'caller_emotional_state')">
                                                <v-expansion-panel-title>
                                                    <div class="d-flex align-center">
                                                        <v-icon class="mr-2">mdi-emoticon-outline</v-icon>
                                                        <span>Caller Emotional State</span>
                                                        <v-spacer></v-spacer>
                                                        <v-chip
                                                            :color="getScoreColor(getEvaluationScore(item.id, 'caller_emotional_state'))"
                                                            size="small" class="ml-2">
                                                            {{ getEvaluationScore(item.id, 'caller_emotional_state') }}
                                                        </v-chip>
                                                    </div>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                    <div class="mb-2">{{ getEvaluationExplanation(item.id,
                                                        'caller_emotional_state') }}</div>
                                                    <div
                                                        v-if="getEvaluationExamples(item.id, 'caller_emotional_state')?.length">
                                                        <div class="font-weight-bold mt-2">Examples:</div>
                                                        <v-list>
                                                            <v-list-item
                                                                v-for="(example, i) in getEvaluationExamples(item.id, 'caller_emotional_state')"
                                                                :key="i">
                                                                <v-list-item-title>{{ example }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </v-expansion-panel-text>
                                            </v-expansion-panel>

                                            <!-- Comprehension Accuracy -->
                                            <v-expansion-panel
                                                v-if="getEvaluationCategory(item.id, 'comprehension_accuracy')">
                                                <v-expansion-panel-title>
                                                    <div class="d-flex align-center">
                                                        <v-icon class="mr-2">mdi-brain</v-icon>
                                                        <span>Comprehension Accuracy</span>
                                                        <v-spacer></v-spacer>
                                                        <v-chip
                                                            :color="getScoreColor(getEvaluationScore(item.id, 'comprehension_accuracy'))"
                                                            size="small" class="ml-2">
                                                            {{ getEvaluationScore(item.id, 'comprehension_accuracy') }}
                                                        </v-chip>
                                                    </div>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                    <div class="mb-2">{{ getEvaluationExplanation(item.id,
                                                        'comprehension_accuracy') }}</div>
                                                    <div
                                                        v-if="getEvaluationExamples(item.id, 'comprehension_accuracy')?.length">
                                                        <div class="font-weight-bold mt-2">Examples:</div>
                                                        <v-list>
                                                            <v-list-item
                                                                v-for="(example, i) in getEvaluationExamples(item.id, 'comprehension_accuracy')"
                                                                :key="i">
                                                                <v-list-item-title>{{ example }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </v-expansion-panel-text>
                                            </v-expansion-panel>

                                            <!-- Human Escalation Requests -->
                                            <v-expansion-panel
                                                v-if="getEvaluationCategory(item.id, 'human_escalation_requests')">
                                                <v-expansion-panel-title>
                                                    <div class="d-flex align-center">
                                                        <v-icon class="mr-2">mdi-account-arrow-up</v-icon>
                                                        <span>Human Escalation Requests</span>
                                                        <v-spacer></v-spacer>
                                                        <v-chip
                                                            :color="getScoreColor(getEvaluationScore(item.id, 'human_escalation_requests'))"
                                                            size="small" class="ml-2">
                                                            {{ getEvaluationScore(item.id, 'human_escalation_requests')
                                                            }}
                                                        </v-chip>
                                                    </div>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                    <div class="mb-2">{{ getEvaluationExplanation(item.id,
                                                        'human_escalation_requests') }}</div>
                                                    <div
                                                        v-if="getEvaluationExamples(item.id, 'human_escalation_requests')?.length">
                                                        <div class="font-weight-bold mt-2">Examples:</div>
                                                        <v-list>
                                                            <v-list-item
                                                                v-for="(example, i) in getEvaluationExamples(item.id, 'human_escalation_requests')"
                                                                :key="i">
                                                                <v-list-item-title>{{ example }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </v-expansion-panel-text>
                                            </v-expansion-panel>

                                            <!-- Information Provision -->
                                            <v-expansion-panel
                                                v-if="getEvaluationCategory(item.id, 'information_provision')">
                                                <v-expansion-panel-title>
                                                    <div class="d-flex align-center">
                                                        <v-icon class="mr-2">mdi-information-outline</v-icon>
                                                        <span>Information Provision</span>
                                                        <v-spacer></v-spacer>
                                                        <v-chip
                                                            :color="getScoreColor(getEvaluationScore(item.id, 'information_provision'))"
                                                            size="small" class="ml-2">
                                                            {{ getEvaluationScore(item.id, 'information_provision') }}
                                                        </v-chip>
                                                    </div>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                    <div class="mb-2">{{ getEvaluationExplanation(item.id,
                                                        'information_provision') }}</div>
                                                    <div
                                                        v-if="getEvaluationExamples(item.id, 'information_provision')?.length">
                                                        <div class="font-weight-bold mt-2">Examples:</div>
                                                        <v-list>
                                                            <v-list-item
                                                                v-for="(example, i) in getEvaluationExamples(item.id, 'information_provision')"
                                                                :key="i">
                                                                <v-list-item-title>{{ example }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </v-expansion-panel-text>
                                            </v-expansion-panel>

                                            <!-- Task Completion -->
                                            <v-expansion-panel v-if="getEvaluationCategory(item.id, 'task_completion')">
                                                <v-expansion-panel-title>
                                                    <div class="d-flex align-center">
                                                        <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
                                                        <span>Task Completion</span>
                                                        <v-spacer></v-spacer>
                                                        <v-chip
                                                            :color="getScoreColor(getEvaluationScore(item.id, 'task_completion'))"
                                                            size="small" class="ml-2">
                                                            {{ getEvaluationScore(item.id, 'task_completion') }}
                                                        </v-chip>
                                                    </div>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                    <div class="mb-2">{{ getEvaluationExplanation(item.id,
                                                        'task_completion') }}</div>
                                                    <div
                                                        v-if="getEvaluationExamples(item.id, 'task_completion')?.length">
                                                        <div class="font-weight-bold mt-2">Examples:</div>
                                                        <v-list>
                                                            <v-list-item
                                                                v-for="(example, i) in getEvaluationExamples(item.id, 'task_completion')"
                                                                :key="i">
                                                                <v-list-item-title>{{ example }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </v-expansion-panel-text>
                                            </v-expansion-panel>

                                            <!-- Successful Call -->
                                            <v-expansion-panel v-if="getEvaluationCategory(item.id, 'successful_call')">
                                                <v-expansion-panel-title>
                                                    <div class="d-flex align-center">
                                                        <v-icon class="mr-2">mdi-check-decagram-outline</v-icon>
                                                        <span>Successful Call</span>
                                                        <v-spacer></v-spacer>
                                                        <v-chip :color="getSuccessColor(getCallSuccessStatus(item.id))"
                                                            size="small" class="ml-2">
                                                            {{ getCallSuccessStatus(item.id) ? 'Yes' : 'No' }}
                                                        </v-chip>
                                                    </div>
                                                </v-expansion-panel-title>
                                                <v-expansion-panel-text>
                                                    <div class="mb-2">{{ getEvaluationExplanation(item.id,
                                                        'successful_call') }}</div>
                                                    <div
                                                        v-if="getEvaluationExamples(item.id, 'successful_call')?.length">
                                                        <div class="font-weight-bold mt-2">Examples:</div>
                                                        <v-list>
                                                            <v-list-item
                                                                v-for="(example, i) in getEvaluationExamples(item.id, 'successful_call')"
                                                                :key="i">
                                                                <v-list-item-title>{{ example }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </div>
                                                </v-expansion-panel-text>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </div>

                                    <div v-else class="text-center my-4">
                                        <v-btn size="small" variant="text" color="primary" class="mb-2"
                                            @click="triggerEvaluation(item.id)" :loading="item.triggeringEvaluation">
                                            Re-trigger Evaluation
                                        </v-btn>
                                        <div>No evaluation data available for this call ({{ item.id }})</div>
                                    </div>
                                </div>
                            </v-sheet>
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <!-- Loading indicator -->
            <div v-if="loading" class="d-flex justify-center align-center pa-4">
                <v-progress-circular indeterminate></v-progress-circular>
            </div>

            <!-- No results message -->
            <div v-if="!loading && conversations.length === 0" class="text-center pa-4">
                No conversations found for this clinic
            </div>

            <!-- Pagination -->
            <div class="d-flex justify-center align-center pa-4">
                <v-btn :loading="loading" :disabled="!hasMore" @click="loadMore" color="primary">
                    Load More
                </v-btn>
            </div>
        </v-card-text>
    </v-card>

    <!-- Summary Dialog -->
    <v-dialog v-model="showSummaryDialog" max-width="600">
        <v-card>
            <v-card-title>Conversation Summary</v-card-title>
            <v-card-text>
                <div v-if="selectedConversation">
                    <div class="text-subtitle-2 font-weight-bold mb-2">Call Details</div>
                    <div class="mb-4">
                        <div><strong>Call SID:</strong> {{ selectedConversation.callSid }}</div>
                        <div><strong>Phone Number:</strong> {{ selectedConversation.phoneNumber }}</div>
                        <div><strong>Started:</strong> {{ formatDate(selectedConversation.startedAt) }}</div>
                        <v-btn :loading="loadingRegenerate" class="mt-2"
                            @click="regenerateSection(selectedConversation.id)">Regenerate</v-btn>
                    </div>

                    <div class="text-subtitle-2 font-weight-bold mb-2">Summary</div>
                    <div class="summary-text" v-html="formatSummary(selectedConversation.summary)"></div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text="Close" variant="text" @click="showSummaryDialog = false"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Interactions Dialog -->
    <v-dialog v-model="showInteractionsDialog" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <v-toolbar>
                <v-btn icon="mdi-close" @click="closeInteractionsDialog"></v-btn>

                <v-toolbar-title prepend-icon="mdi-chat">
                    Conversation Details
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-items>
                    <v-tooltip text="Show Summary">
                        <template #activator="{ props }">
                            <v-btn icon="mdi-text-box-outline" v-bind="props"
                                @click="openSummary(selectedConversation)"></v-btn>
                        </template>
                    </v-tooltip>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <div class="conversation-container">
                    <div v-if="loadingInteractions" class="d-flex justify-center align-center pa-4">
                        <v-progress-circular indeterminate></v-progress-circular>
                    </div>

                    <div v-else class="interactions-list">
                        <div v-for="(interaction, index) in interactions" :key="index" :class="[
                            'interaction-bubble',
                            interaction.speaker === 'ai-agent' ? 'agent' : 'human'
                        ]">
                            <div class="interaction-content">
                                <div class="speaker-label">{{ interaction.speaker === 'ai-agent' ? 'Agent' : 'User' }}
                                </div>
                                <div class="message">
                                    {{ interaction.speaker === 'ai-agent' ? interaction.label : interaction.text }}
                                </div>
                                <div class="timestamp" v-if="interaction.timestamp">
                                    {{ formatTime(interaction.timestamp) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <!-- Snackbar for copy confirmation -->
    <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackbarText }}
    </v-snackbar>
</template>

<script setup>
import { db } from '@/firebase';
import axios from 'axios';
import { computed, defineProps, onMounted, ref, watch } from 'vue';

const props = defineProps({
    clinicId: {
        type: String,
        required: true
    }
});

// Table headers
const headers = [
    { title: '', key: 'data-table-expand', sortable: false, width: '100px' },
    { title: 'Status', key: 'callStatus', align: 'center', sortable: false },
    { title: 'Call SID', key: 'callSid', sortable: true, width: '150px' },
    { title: '', key: 'summary_action', align: 'center', sortable: false, width: '50px' },
    { title: '', key: 'interactions_action', align: 'center', sortable: false, width: '50px' },
    { title: 'Started At', key: 'startedAt', sortable: true },
    { title: 'Duration', key: 'duration', sortable: false },
    { title: 'Recording', key: 'recording', sortable: false },
    { title: 'Type', key: 'type', align: 'center', sortable: false },
    { title: 'Actions', key: 'end_action', align: 'center', sortable: false, width: '100px' }
];

// Pagination state
const conversations = ref([]);
const loading = ref(false);
const lastVisible = ref(null);
const hasMore = ref(true);
const pageSize = 10;

// UI state
const search = ref('');
const showSummaryDialog = ref(false);
const selectedConversation = ref(null);
const showInteractionsDialog = ref(false);
const interactions = ref([]);
const loadingInteractions = ref(false);
const loadingRegenerate = ref(false);
const refreshing = ref(false);
const snackbar = ref(false);
const snackbarText = ref('');
const evaluations = ref({});
const loadingEvaluation = ref(false);

// Computed
const filteredConversations = computed(() => {
    if (!search.value) return conversations.value;

    const searchTerm = search.value.toLowerCase();
    return conversations.value.filter(conv =>
        conv.callSid?.toLowerCase().includes(searchTerm) ||
        conv.identifier?.toLowerCase().includes(searchTerm) ||
        conv.phoneNumber?.includes(searchTerm)
    );
});

// Methods
const fetchConversations = async (searchTerm = '') => {
    if (loading.value) return;

    loading.value = true;
    conversations.value = []; // Clear existing conversations

    try {
        let query = db.collection('conversations')
            .where('clinicId', '==', props.clinicId);

        // If there's a search term, modify the query
        if (searchTerm) {
            // Option 1: Search for exact matches on callSid
            if (searchTerm.length > 5) { // Only search callSid for longer strings to avoid excessive queries
                query = db.collection('conversations')
                    .where('clinicId', '==', props.clinicId)
                    .where('callSid', '>=', searchTerm)
                    .where('callSid', '<=', searchTerm + '\uf8ff')
                    .orderBy('callSid')
                    .limit(pageSize);
            } else {
                // For short search terms, we'll filter client-side from results
                query = db.collection('conversations')
                    .where('clinicId', '==', props.clinicId)
                    .orderBy('startedAt', 'desc')
                    .limit(50); // Increase limit for better search
            }
        } else {
            // Default ordering for non-search
            query = query.orderBy('startedAt', 'desc').limit(pageSize);
        }

        const snapshot = await query.get();

        if (!searchTerm || searchTerm.length > 5) {
            // For call SID searches or no search
            lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
            hasMore.value = snapshot.docs.length === pageSize;
        } else {
            // For short search terms, we filter client side and don't use pagination
            hasMore.value = false;
        }

        let results = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            tasks: doc.data().tasks?.map(task => task.name).join(', ') || '-',
            startedAt: doc.data().startedAt?.toDate(),
            endedAt: doc.data().endedAt?.toDate(),
            updatingEnd: false
        }));

        // Client-side filtering for short search terms
        if (searchTerm && searchTerm.length <= 5) {
            const term = searchTerm.toLowerCase();
            results = results.filter(conv =>
                conv.callSid?.toLowerCase().includes(term) ||
                conv.identifier?.toLowerCase().includes(term) ||
                conv.phoneNumber?.includes(term)
            );
        }

        conversations.value = results;

        // Fetch evaluations for all loaded conversations
        await fetchAllEvaluations();

    } catch (error) {
        console.error('Error fetching conversations:', error);
    } finally {
        loading.value = false;
    }
};

const loadMore = async () => {
    if (loading.value || !hasMore.value || search.value) return; // Disable loadMore during search

    loading.value = true;
    try {
        const query = db.collection('conversations')
            .where('clinicId', '==', props.clinicId)
            .orderBy('startedAt', 'desc')
            .startAfter(lastVisible.value)
            .limit(pageSize);

        const snapshot = await query.get();

        lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
        hasMore.value = snapshot.docs.length === pageSize;

        const newConversations = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            tasks: doc.data().tasks?.map(task => task.name).join(', ') || '-',
            startedAt: doc.data().startedAt?.toDate(),
            endedAt: doc.data().endedAt?.toDate(),
            updatingEnd: false
        }));

        conversations.value = [...conversations.value, ...newConversations];

        // Fetch evaluations for newly loaded conversations
        await fetchAllEvaluations();
    } catch (error) {
        console.error('Error loading more conversations:', error);
    } finally {
        loading.value = false;
    }
};

const searchDebounceTimeout = ref(null);


const performSearch = () => {
    // Clear any existing timeout
    if (searchDebounceTimeout.value) {
        clearTimeout(searchDebounceTimeout.value);
    }

    // Set a new timeout to debounce the search
    searchDebounceTimeout.value = setTimeout(() => {
        fetchConversations(search.value);
    }, 500); // 500ms debounce
};

watch(search, () => {
    performSearch();
});


const updateEndTime = async (conversationId) => {
    const conversationIndex = conversations.value.findIndex(c => c.id === conversationId);
    if (conversationIndex === -1) return;
    conversations.value[conversationIndex].updatingEnd = true;

    try {
        // Get the conversation's startedAt time and add 1 hour - well we can play around with this
        const startedAt = conversations.value[conversationIndex].startedAt;

        let endedAt = new Date(startedAt.getTime() + (60 * 60 * 1000));

        await db.collection('conversations').doc(conversationId).update({
            endedAt: endedAt,
            callStatus: 'completed'
        });

        conversations.value[conversationIndex].endedAt = endedAt;
        conversations.value[conversationIndex].callStatus = 'completed';

        if (selectedConversation.value?.id === conversationId) {
            selectedConversation.value.endedAt = endedAt;
            selectedConversation.value.callStatus = 'completed';
        }
    } catch (error) {
        console.error('Error updating end time:', error);
    } finally {
        conversations.value[conversationIndex].updatingEnd = false;
    }
};

const fetchInteractions = async (conversationId) => {
    loadingInteractions.value = true;
    try {
        const snapshot = await db
            .collection('conversations')
            .doc(conversationId)
            .collection('interactions')
            .orderBy('timestamp', 'asc')
            .get();

        interactions.value = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate()
        }));
    } catch (error) {
        console.error('Error fetching interactions:', error);
    } finally {
        loadingInteractions.value = false;
    }
};

const fetchAllEvaluations = async () => {
    loadingEvaluation.value = true;

    try {
        const conversationsToFetch = conversations.value.filter(c => !evaluations.value[c.id]);

        console.log(`Fetching evaluations for ${conversationsToFetch.length} conversations`);

        const fetchPromises = conversationsToFetch.map(async (conversation) => {
            if (!conversation || !conversation.id) return;

            try {
                // Try both possible paths
                let evaluationRef = db.collection('conversations')
                    .doc(conversation.id)
                    .collection('evaluations')
                    .doc('gpt-evaluation');

                let doc = await evaluationRef.get();

                // If not found, try with callSid
                if (!doc.exists && conversation.callSid) {
                    evaluationRef = db.collection('conversations')
                        .doc(conversation.callSid)
                        .collection('evaluations')
                        .doc('gpt-evaluation');

                    doc = await evaluationRef.get();
                }

                if (doc.exists) {
                    const data = doc.data();
                    evaluations.value[conversation.id] = data;
                } else {
                    evaluations.value[conversation.id] = null;
                }
            } catch (err) {
                console.error(`Error fetching evaluation for conversation ${conversation.id}:`, err);
                evaluations.value[conversation.id] = null;
            }
        });

        await Promise.all(fetchPromises);

    } catch (error) {
        console.error('Error fetching evaluations:', error);
    } finally {
        loadingEvaluation.value = false;
    }
};

const fetchEvaluation = async (conversationId) => {
    if (evaluations.value[conversationId]) return;

    loadingEvaluation.value = true;
    console.log(`Fetching individual evaluation for conversation ID: ${conversationId}`);

    try {
        // Use same logic as fetchAllEvaluations but for a single conversation
        const conversation = conversations.value.find(c => c.id === conversationId);

        if (!conversation || !conversation.id) {
            evaluations.value[conversationId] = null;
            return;
        }

        // Try both possible paths
        let evaluationRef = db.collection('conversations')
            .doc(conversationId)
            .collection('evaluations')
            .doc('gpt-evaluation');

        let doc = await evaluationRef.get();

        // If not found, try with callSid
        if (!doc.exists && conversation.callSid) {
            evaluationRef = db.collection('conversations')
                .doc(conversation.callSid)
                .collection('evaluations')
                .doc('gpt-evaluation');

            doc = await evaluationRef.get();
        }

        if (doc.exists) {
            const data = doc.data();
            evaluations.value[conversationId] = data;
        } else {
            evaluations.value[conversationId] = null;
        }
    } catch (error) {
        console.error('Error fetching individual evaluation:', error);
        evaluations.value[conversationId] = null;
    } finally {
        loadingEvaluation.value = false;
    }
};

const viewConversation = async (conversation) => {
    selectedConversation.value = conversation;
    showInteractionsDialog.value = true;
    await fetchInteractions(conversation.callSid);
};

const closeInteractionsDialog = () => {
    showInteractionsDialog.value = false;
    selectedConversation.value = null;
    interactions.value = [];
};

const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleString();
};

const formatTime = (timestamp) => {
    if (!timestamp) return '';
    return new Date(timestamp).toLocaleTimeString();
};

const formatSummary = (html) => {
    if (!html) return 'No summary available';
    return html
        .replace(/\n/g, '<br>')
        .replace(/\s{2,}/g, ' ');
};

const calculateDuration = (startDate, endDate) => {
    if (!startDate || !endDate) return 'N/A';

    const diffInMinutes = Math.floor((endDate - startDate) / (1000 * 60));
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    if (hours > 0) {
        return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
};

const openSummary = (conversation) => {
    selectedConversation.value = conversation;
    showSummaryDialog.value = true;
};

const playRecording = (recordingSid) => {
    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
        ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
        : 'https://us-central1-mdhub-staging.cloudfunctions.net';
    return `${baseUrl}/playAudio?recordingSid=${recordingSid}`;
};

const regenerateSection = async (id) => {
    if (loadingRegenerate.value) return;
    loadingRegenerate.value = true;

    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
        ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
        : 'https://us-central1-mdhub-staging.cloudfunctions.net';

    try {
        await axios.post(
            `${baseUrl}/regenerateConversationSummaryScrapper`,
            { id },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }
        );

        await fetchConversations();
        selectedConversation.value = conversations.value.find(conv => conv.id === id);
    } catch (error) {
        console.error('Error regenerating section:', error);
    } finally {
        loadingRegenerate.value = false;
    }
};

const getStatusColor = (status) => {
    const colors = {
        'ready_to_call': 'info',
        'completed': 'success',
        'no-answer': 'error',
        'in-progress': 'warning',
        'call_back': 'warning'
    };
    return colors[status?.toLowerCase()] || 'grey';
};

const getScoreColor = (score) => {
    if (!score) return 'grey';

    if (score >= 9) return 'success';
    if (score >= 7) return 'info';
    if (score >= 5) return 'warning';
    return 'error';
};

const getEvaluationCategory = (conversationId, category) => {
    const evaluation = evaluations.value[conversationId];
    if (!evaluation) return null;

    // Check both possible structures
    if (evaluation.evaluation?.evaluation?.[category]) {
        return evaluation.evaluation.evaluation[category];
    } else if (evaluation.evaluation?.[category]) {
        return evaluation.evaluation[category];
    } else if (evaluation[category]) {
        return evaluation[category];
    }
    return null;
};

const getEvaluationScore = (conversationId, category) => {
    const categoryData = getEvaluationCategory(conversationId, category);
    return categoryData?.score || 0;
};

const getEvaluationExplanation = (conversationId, category) => {
    const categoryData = getEvaluationCategory(conversationId, category);
    return categoryData?.explanation || 'No explanation available';
};

const getEvaluationExamples = (conversationId, category) => {
    const categoryData = getEvaluationCategory(conversationId, category);
    return categoryData?.examples || [];
};

const getOverallScore = (conversationId) => {
    const evaluation = evaluations.value[conversationId];
    if (!evaluation) return null;

    // Check all possible structures
    return evaluation.median_score ||
        evaluation.evaluation?.median_score ||
        evaluation.evaluation?.evaluation?.median_score;
};

const getSuccessColor = (success) => {
    return success ? 'success' : 'error';
};

const getCallSuccessStatus = (conversationId) => {
    const successData = getEvaluationCategory(conversationId, 'successful_call');
    return successData?.success || false;
};

const getSuccessButtonText = (conversationId) => {
    // Check if we have evaluation data for this conversation
    if (!evaluations.value[conversationId]) return 'Evaluator';

    const successData = getEvaluationCategory(conversationId, 'successful_call');
    if (!successData) return 'Evaluator';

    return successData.success ? 'Success' : 'Failed';
};

const getSuccessButtonColor = (conversationId) => {
    // Check if we have evaluation data for this conversation
    if (!evaluations.value[conversationId]) return 'medium-emphasis';

    const successData = getEvaluationCategory(conversationId, 'successful_call');
    if (!successData) return 'medium-emphasis';

    return successData.success ? 'success' : 'error';
};

const getPhoneCallTypeIcon = (type) => {
    const icons = {
        'incoming': 'mdi-phone-incoming-outline',
        'outgoing': 'mdi-phone-outgoing-outline'
    };
    return icons[type];
};

const refreshConversations = async () => {
    if (refreshing.value) return;
    refreshing.value = true;

    try {
        conversations.value = [];
        lastVisible.value = null;
        hasMore.value = true;
        search.value = ''; // Clear search on refresh
        await fetchConversations();
    } catch (error) {
        console.error('Error refreshing conversations:', error);
    } finally {
        refreshing.value = false;
    }
};

const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            // Optional: Show a success message or notification
            console.log('SID copied to clipboard');
            snackbar.value = true;
            snackbarText.value = 'SID copied to clipboard';
        })
        .catch(err => {
            console.error('Failed to copy: ', err);
        });
};

const triggerEvaluation = async (conversationId) => {
    // Find the conversation and set loading state
    const conversationIndex = conversations.value.findIndex(c => c.id === conversationId);
    if (conversationIndex === -1) return;

    conversations.value[conversationIndex].triggeringEvaluation = true;

    try {
        const isProduction = process.env.NODE_ENV === 'production';
        const baseUrl = isProduction
            ? 'https://task-agent-950197126940.us-east1.run.app'
            : 'https://task-agent-456450448298.us-east1.run.app';

        // Create the request payload
        const payload = {
            conversationId: conversationId,
            type: "conversations",
            clinicName: conversations.value[conversationIndex].clinicId
        };
        console.log('Request payload:', payload);
        console.log('API key:', process.env.VUE_APP_CLOUD_FUNCTIONS_API_KEY);
        console.log('Base URL:', baseUrl);

        // Call backend function to trigger pubsub event
        const response = await axios.post(
            `${baseUrl}/triggerEvaluation`,
            payload,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.VUE_APP_CLOUD_FUNCTIONS_API_KEY
                },
            }
        );

        console.log('Evaluation trigger response:', response.data);
        snackbar.value = true;
        snackbarText.value = 'Evaluation re-triggered successfully';
        await fetchEvaluation(conversationId);

    } catch (error) {
        console.error('Error triggering evaluation:', error);
        console.error('Error details:', error.response?.data || error.message);
        snackbar.value = true;
        snackbarText.value = 'Failed to re-trigger evaluation';
    } finally {
        conversations.value[conversationIndex].triggeringEvaluation = false;
    }
};

// Watchers
watch(() => props.clinicId, () => {
    if (props.clinicId) {
        lastVisible.value = null;
        hasMore.value = true;
        conversations.value = [];
        fetchConversations();
    }
});

// Lifecycle
onMounted(() => {
    if (props.clinicId) {
        fetchConversations();
    }
});
</script>

<style scoped>
.max-w-xs {
    max-width: 300px;
}

.summary-text :deep(p) {
    margin-bottom: 1em;
}

.summary-text :deep(ul),
.summary-text :deep(ol) {
    margin-left: 1.5em;
    margin-bottom: 1em;
}

.summary-text {
    line-height: 1.5;
    max-height: 400px;
    overflow-y: auto;
    padding: 1rem;
    background-color: #212121;
    border-radius: 4px;
}

.summary-text :deep(strong) {
    font-weight: 600;
}

.summary-text :deep(em) {
    font-style: italic;
}

.conversation-container {
    height: calc(100vh - 64px);
    overflow-y: auto;
    padding: 20px;
    background-color: #212121;
}

.interactions-list {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.interaction-bubble {
    max-width: 70%;
    margin: 8px 0;
}

.interaction-bubble.agent {
    align-self: flex-start;
}

.interaction-bubble.human {
    align-self: flex-end;
}

.interaction-content {
    padding: 12px 16px;
    border-radius: 12px;
    position: relative;
}

.interaction-bubble.agent .interaction-content {
    background-color: #424242;
    border-bottom-left-radius: 4px;
}

.speaker-label {
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 4px;
}

.message {
    white-space: pre-wrap;
    word-break: break-word;
}

.timestamp {
    font-size: 0.7rem;
    color: #999;
    margin-top: 4px;
    text-align: right;
}

.evaluation-container {
    margin-top: 1rem;
}

.evaluation-container .v-expansion-panels {
    margin-top: 1rem;
}

.evaluation-container .v-list {
    background-color: transparent;
    padding: 0;
}

.evaluation-container .v-list-item {
    min-height: auto;
    padding: 4px 0;
}

.evaluation-container .v-list-item-title {
    font-size: 0.9rem;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
}
</style>