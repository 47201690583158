<template>
    <div>
        <v-btn variant="tonal" prepend-icon="mdi-phone-dial" @click="openDialog">
            Numbers to Call
        </v-btn>

        <v-dialog v-model="dialog" width="900">
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center pa-4">
                    <div class="d-flex align-center">
                        Phone Numbers to Call
                        <v-btn color="primary" variant="tonal" class="ml-4" prepend-icon="mdi-plus" @click="addNewRow">
                            Add Number
                        </v-btn>
                        <v-btn color="secondary" variant="tonal" class="ml-2" prepend-icon="mdi-file-upload"
                            @click="$refs.csvUpload.click()">
                            Upload CSV
                        </v-btn>
                        <input ref="csvUpload" type="file" accept=".csv" style="display: none"
                            @change="handleCsvUpload" />

                    </div>
                    <v-btn color="secondary" :loading="calling" variant="tonal" class="ml-2"
                        prepend-icon="mdi-file-upload" @click="performCalls">
                        Call
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <!-- Filters Section -->
                    <div class="d-flex align-center mb-4">
                        <v-select v-model="selectedStatuses" :items="statusOptions" label="Filter by Status" multiple
                            chips class="mr-4" style="max-width: 300px"
                            @update:model-value="handleStatusFilter"></v-select>

                        <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Search" single-line
                            hide-details density="compact" class="table-search" style="max-width: 300px"></v-text-field>
                    </div>

                    <v-data-table v-model="selected" :headers="headers" :items="phoneNumbers" :search="search"
                        :loading="loading" show-select class="elevation-1">
                        <template v-slot:[`item.phoneNumber`]="{ item }">
                            <div class="d-flex align-center">
                                <v-text-field v-if="editingRow === item.id" v-model="item.phoneNumber" density="compact"
                                    hide-details class="mr-2"></v-text-field>
                                <span v-else>{{ item.phoneNumber }}</span>
                            </div>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip :color="getStatusColor(item.status)" size="small">
                                {{ item.status }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.lastCalledAt`]="{ item }">
                            {{ formatDate(item.lastCalledAt) }}
                        </template>
                        <template v-slot:[`item.followUpAt`]="{ item }">
                            {{ item.followUpAt || '' }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="d-flex justify-end">
                                <v-btn v-if="editingRow === item.id" icon="mdi-check" size="small" color="success"
                                    class="mr-1" @click="saveRow(item)"></v-btn>
                                <v-btn v-if="editingRow === item.id" icon="mdi-close" size="small" color="error"
                                    @click="cancelEdit()"></v-btn>
                                <v-btn v-else icon="mdi-pencil" size="small" @click="editRow(item)"></v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="tonal" @click="dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="showError" color="error" timeout="3000">
            {{ errorMessage }}
        </v-snackbar>
    </div>
</template>

<script setup>
import { db } from '@/firebase';
import { performOutboundCalls } from '@/functions/performOutboundCalls';
import Papa from 'papaparse';
import { computed, defineProps, ref, watch } from 'vue';

const props = defineProps({
    clinicId: {
        type: String,
        required: true
    }
});

const dialog = ref(false);
const search = ref('');
const loading = ref(false);
const phoneNumbers = ref([]);
const selected = ref([]);
const editingRow = ref(null);
const showError = ref(false);
const errorMessage = ref('');
const csvUpload = ref(null);
const clinicId = computed(() => props.clinicId);
const calling = ref(false);
const selectedStatuses = ref([]);
const statusOptions = [
    { title: 'Ready to Call', value: 'ready_to_call' },
    { title: 'In Progress', value: 'in_progress' },
    { title: 'Completed', value: 'completed' },
    { title: 'No Answer', value: 'no-answer' },
    { title: 'Call Back', value: 'call_back' }
];

const headers = [
    {
        title: 'Phone Number',
        align: 'start',
        key: 'phoneNumber'
    },
    {
        title: 'Status',
        align: 'center',
        key: 'status'
    },
    {
        title: 'Last Called',
        align: 'end',
        key: 'lastCalledAt'
    },
    {
        title: 'Follow Up At',
        align: 'end',
        key: 'followUpAt'
    },
    {
        title: 'Actions',
        align: 'end',
        key: 'actions',
        sortable: false
    }
];

const getStatusColor = (status) => {
    const colors = {
        'ready_to_call': 'info',
        'completed': 'success',
        'no-answer': 'error',
        'in_progress': 'warning',
        'call_back': 'warning'
    };
    return colors[status?.toLowerCase()] || 'grey';
};

const formatDate = (timestamp) => {
    if (!timestamp) return 'Never';
    const date = new Date(timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000));

    return date.toLocaleString();
};

const fetchPhoneNumbers = async () => {
    loading.value = true;
    try {
        let query = db
            .collection('clinics')
            .doc(clinicId.value)
            .collection('patientPhoneNumbers');

        if (selectedStatuses.value.length > 0) {
            query = query.where('status', 'in', selectedStatuses.value);
        }

        const snapshot = await query.get();

        if (snapshot.empty) {
            phoneNumbers.value = [];
            return;
        }

        phoneNumbers.value = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error('Error fetching phone numbers:', error);
        showError.value = true;
        errorMessage.value = 'Error fetching phone numbers';
    } finally {
        loading.value = false;
    }
};

const handleStatusFilter = () => {
    fetchPhoneNumbers();
};

const addNewRow = () => {
    const newRow = {
        id: 'new-' + Date.now(),
        phoneNumber: '',
        status: 'ready_to_call',
        lastCalledAt: null,
        followUpAt: null,
        isNew: true
    };
    phoneNumbers.value.unshift(newRow);
    editingRow.value = newRow.id;
};

const editRow = (row) => {
    editingRow.value = row.id;
};

const cancelEdit = () => {
    const index = phoneNumbers.value.findIndex(p => p.id === editingRow.value);
    if (index !== -1 && phoneNumbers.value[index].isNew) {
        phoneNumbers.value.splice(index, 1);
    }
    editingRow.value = null;
};

const saveRow = async (row) => {
    try {
        const phoneNumberData = {
            phoneNumber: row.phoneNumber,
            status: row.status || 'ready_to_call',
            lastCalledAt: row.lastCalledAt || null,
            followUpAt: row.followUpAt || null,
            createdAt: new Date().toISOString()
        };

        const collectionRef = db
            .collection('clinics')
            .doc(clinicId.value)
            .collection('patientPhoneNumbers');

        if (row.isNew) {
            await collectionRef.add(phoneNumberData);
        } else {
            await collectionRef.doc(row.id).update(phoneNumberData);
        }

        editingRow.value = null;
        await fetchPhoneNumbers();
    } catch (error) {
        console.error('Error saving phone number:', error);
        showError.value = true;
        errorMessage.value = error.message || 'Error saving phone number';
    }
};

const handleCsvUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    loading.value = true;

    Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
            if (results.errors.length > 0) {
                showError.value = true;
                errorMessage.value = 'Error parsing CSV file: ' + results.errors[0].message;
                loading.value = false;
                return;
            }

            try {
                const batch = db.batch();
                const collectionRef = db
                    .collection('clinics')
                    .doc(clinicId.value)
                    .collection('patientPhoneNumbers');

                let validNumbers = 0;

                for (const row of results.data) {
                    if (!row.phoneNumber) continue;

                    const docRef = collectionRef.doc();
                    batch.set(docRef, {
                        phoneNumber: row.phoneNumber,
                        status: 'ready_to_call',
                        lastCalledAt: null,
                        followUpAt: null,
                        createdAt: new Date().toISOString()
                    });
                    validNumbers++;
                }

                if (validNumbers === 0) {
                    showError.value = true;
                    errorMessage.value = 'No valid phone numbers found in CSV';
                    loading.value = false;
                    return;
                }

                await batch.commit();
                selectedStatuses.value = [];
                await fetchPhoneNumbers();
            } catch (error) {
                console.error('Error uploading CSV:', error);
                showError.value = true;
                errorMessage.value = error.message || 'Error uploading CSV data';
            } finally {
                loading.value = false;
            }
        },
        error: (error) => {
            console.error('CSV parsing error:', error);
            showError.value = true;
            errorMessage.value = 'Error reading CSV file';
            loading.value = false;
        }
    });

    event.target.value = '';
};

const openDialog = () => {
    dialog.value = true;
    selectedStatuses.value = [];
    fetchPhoneNumbers();
};

const performCalls = async () => {
    calling.value = true;
    const selectedPhoneNumbers = Object.values(selected.value);
    console.log('Performing calls:', selectedPhoneNumbers);
    // here i need to call the performOutboundCalls functions from mdhub backend with the selected phone numbers
    await performOutboundCalls(selectedPhoneNumbers, clinicId.value);
    console.log('Calls performed');
    calling.value = false;
};

const listenToClinicChanges = () => {
    let unsubscribeSubCollection = null;
    if (unsubscribeSubCollection) {
        unsubscribeSubCollection();
    }

    const ref = db.collection('clinics').doc(clinicId.value);

    unsubscribeSubCollection = ref.collection('patientPhoneNumbers').onSnapshot((snapshot) => {
        phoneNumbers.value = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    });
};

watch(dialog, (opened) => {
    phoneNumbers.value = [];
    selected.value = [];
    if (opened) {
        listenToClinicChanges();
    }
}, { immediate: true });


watch(selected, (newval) => {
    console.log('Selected:', newval);


})
</script>

<style scoped>
.table-search {
    max-width: 300px;
}
</style>