<template>
    <v-dialog v-model="appointmentTypesDialog" width="900" persistent>
        <v-card>
            <v-card-title class="d-flex justify-space-between align-center pa-4">
                Appointment Types for {{ clinic?.name }}
                <v-btn icon="mdi-close" @click="closeDialog" variant="text"></v-btn>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col>
                        <v-btn color="primary" prepend-icon="mdi-plus" variant="tonal" @click="addNewAppointmentType"
                            class="mb-4">
                            Add Appointment Type
                        </v-btn>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="appointmentTypes" :loading="loading" class="elevation-1"
                    :items-per-page="10">
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex gap-2">
                            <v-btn size="small" icon variant="text" color="primary" @click="editAppointmentType(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="editDialog" max-width="500px">
            <v-card>
                <v-card-title class="text-h6">
                    {{ isNewAppointmentType ? 'Add Appointment Type' : 'Edit Appointment Type' }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="formValid">
                        <v-text-field v-model="editedItem.treatmentType" label="Type" required
                            :rules="[v => !!v || 'Type is required']"></v-text-field>

                        <v-text-field v-model="editedItem.duration" label="Duration (minutes)" type="number" required
                            :rules="[
                                v => !!v || 'Duration is required',
                                v => Number(v) > 0 || 'Duration must be greater than 0'
                            ]"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" variant="text" @click="closeEditDialog">Cancel</v-btn>
                    <v-btn color="primary" variant="elevated" @click="saveAppointmentType" :disabled="!formValid"
                        :loading="saving">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="3000">
            {{ snackbar.message }}
        </v-snackbar>
    </v-dialog>
</template>

<script setup>
import { getFunctions, httpsCallable } from 'firebase/functions';
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue';

const emit = defineEmits(['update:modelValue', 'appointment-types-updated']);

const props = defineProps({
    clinic: {
        type: Object,
        required: true
    },
    modelValue: {
        type: Boolean,
        default: false
    }
});

const appointmentTypesDialog = ref(props.modelValue);
// Data table
const headers = [
    { title: 'Type', key: 'treatmentType', sortable: true },
    { title: 'Duration (min)', key: 'duration', sortable: true, },
    { title: 'Actions', key: 'actions', sortable: false }
];

// State management
const appointmentTypes = ref([]);
const loading = ref(true);
const editDialog = ref(false);
const isNewAppointmentType = ref(false);
const editedItem = ref({ treatmentType: '', duration: '' });
const saving = ref(false);
const formValid = ref(false);
const form = ref(null);
const snackbar = ref({
    show: false,
    message: '',
    color: 'success'
});

const fetchAppointmentTypes = async () => {
    if (!props.clinic || !props.clinic.id) return;

    loading.value = true;
    try {
        const functions = getFunctions();
        const getClinicAppointmentTypes = httpsCallable(functions, 'getClinicTreatmentTypesForScraper');

        const result = await getClinicAppointmentTypes({ clinicId: props.clinic.id });
        appointmentTypes.value = result.data.data.clinicAppointmentTypes || [];
        console.log('i got them', result)
    } catch (error) {
        console.error('Error fetching appointment types:', error);
        showSnackbar('Error loading appointment types', 'error');
    } finally {
        loading.value = false;
    }
};

const addNewAppointmentType = () => {
    isNewAppointmentType.value = true;
    editedItem.value = { treatmentType: '', duration: '' };
    editDialog.value = true;
};

const editAppointmentType = (item) => {
    console.log('edit item', item)
    isNewAppointmentType.value = false;
    editedItem.value = item;
    editDialog.value = true;
};

const closeEditDialog = () => {
    editDialog.value = false;
    setTimeout(() => {
        editedItem.value = { treatmentType: '', duration: '' };
    }, 300);
};

const saveAppointmentType = async () => {
    if (!form.value.validate()) return;

    saving.value = true;
    try {
        const functions = getFunctions();
        const action = isNewAppointmentType.value
            ? httpsCallable(functions, 'setClinicTreatmentTypesForScrapper')
            : httpsCallable(functions, 'updateClinicTreatmentTypesForScraper');

        const payload = {
            clinicId: props.clinic.id,
            treatmentType: editedItem.value.treatmentType.trim(),
            duration: Number(editedItem.value.duration)
        };

        if (!isNewAppointmentType.value) {
            payload.originalType = editedItem.value.originalType || editedItem.value.type;
        }

        await action(payload);
        await fetchAppointmentTypes();
        emit('appointment-types-updated');

        showSnackbar(
            isNewAppointmentType.value
                ? 'Appointment type added successfully!'
                : 'Appointment type updated successfully!'
        );

        closeEditDialog();
    } catch (error) {
        console.error('Error saving appointment type:', error);
        showSnackbar('Error saving appointment type', 'error');
    } finally {
        saving.value = false;
    }
};

const closeDialog = () => {
    appointmentTypesDialog.value = false;
    emit('update:modelValue', false)
};

const showSnackbar = (message, color = 'success') => {
    snackbar.value = {
        show: true,
        message,
        color
    };
};

watch(() => appointmentTypesDialog.value, (newVal) => {
    if (newVal && props.clinic) {
        fetchAppointmentTypes();
    }
});

onMounted(() => {
    if (appointmentTypesDialog.value && props.clinic) {
        fetchAppointmentTypes();
    }
});
</script>

<style scoped>
.max-width-250 {
    max-width: 250px;
}
</style>