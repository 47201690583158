<template>
    <v-card>
        <v-card-title>
            schedule
        </v-card-title>
        <v-card-text>
            <div class="d-flex flex-column">
                <v-autocomplete :loading="gettingSchedule" :disabled="gettingSchedule" :items="doctors"
                    item-title="name" item-value="userId" v-model="selectedUserId"></v-autocomplete>

                <div class="d-flex flex-column" v-if="selectedSchedule">
                    <div class="d-flex align-center">
                        <h3>{{ selectedSchedule.name }}</h3>
                        <v-btn class="ml-4" @click="getAllDays()">Edit all days</v-btn>
                    </div>
                    <v-divider class="my-3"></v-divider>
                    <div class="d-flex justify-space-between">
                        <div v-for="(workingDay, i) in sortedWorkingDays" :key="i">
                            <v-hover>
                                <template v-slot:default="{ isHovering, props }">
                                    <h4 @click="selectWorkingDay(workingDay)" v-bind="props"
                                        :class="isHovering ? 'dayHover' : ''">{{ workingDay.day.name }}</h4>
                                </template>
                            </v-hover>
                            <div class="d-flex flex-column">
                                <div>
                                    <p>from: {{ parseTime(workingDay.workingHours.from) }}</p>
                                    <p>to: {{ parseTime(workingDay.workingHours.to) }}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <v-divider class="my-3"></v-divider>
                    <div>
                        <h3>Treatment types</h3>

                        <v-btn class="mt-2" @click="updateTreatmentTypes()" :loading="updatingTreatmentTypes">Update treatment types</v-btn>

                        <v-checkbox v-model="selectedDoctorAppointmentDurations" :disabled="updatingTreatmentTypes"
                            v-for="(clinicAppointmentDuration, i) in clinicAppointmentDurations" :key="i" hide-details
                            variant="compact" :label="clinicAppointmentDuration.treatmentType"
                            :value="clinicAppointmentDuration.id"></v-checkbox>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>

    <v-dialog v-model="editingWorkingDayDialog" width="800">
        <SingleDoctorSingleDayDialog :working-day="editingWorkingDay" :timezone="props.clinic.timezone"
            @saved="singleDoctorSingleDaySaved" @close="editingWorkingDayDialog = false" />
    </v-dialog>

    <v-dialog v-model="singleDoctorAllDaysDialog" width="800">
        <SingleDoctorAllDayDialog :selected-schedule="selectedSchedule" :days="days" :timezone="props.clinic.timezone"
            @saved="singleDoctorAllDaySaved" @close="singleDoctorAllDaysDialog = false" />
    </v-dialog>
</template>

<script setup>
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref, defineProps, computed, onMounted, watch } from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import SingleDoctorAllDayDialog from './SingleDoctorAllDayDialog.vue';
import SingleDoctorSingleDayDialog from './SingleDoctorSingleDayDialog.vue';

dayjs.extend(utc);
dayjs.extend(timezone);

const functions = getFunctions()

const props = defineProps({
    clinic: {
        type: Object
    }
});

const schedule = ref([])
const doctors = ref([])
const days = ref([])
const clinicAppointmentDurations = ref([])
const gettingSchedule = ref(false)

onMounted(async () => {
    await getSchedule()
})

const getSchedule = async () => {
    const getScheduleFromCalendarForScrapper = httpsCallable(functions, 'getScheduleFromCalendarForScrapper')

    gettingSchedule.value = true
    const result = await getScheduleFromCalendarForScrapper({
        clinicId: props.clinic.id
    })

    schedule.value = result.data.data.workingHours

    days.value = result.data.data.days

    clinicAppointmentDurations.value = result.data.data.clinicAppointmentDurations

    doctors.value = schedule.value?.map(doctor => {
        return {
            userId: doctor.userId,
            name: doctor.name
        }
    })

    gettingSchedule.value = false
}

const selectedUserId = ref(null)
const selectedSchedule = ref(null)
const selectedDoctorAppointmentDurations = ref([])

watch(selectedUserId, (val) => {
    selectedSchedule.value = schedule.value.find(schedule => schedule.userId === val)
    selectedDoctorAppointmentDurations.value = selectedSchedule.value.appointmentDurations.length > 0 ? selectedSchedule.value.appointmentDurations.map(item => item.clinicAppointmentDurationId) : []
})

const dayOrder = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
};

const sortedWorkingDays = computed(() => {
    if (!selectedSchedule.value?.workingDays) return [];
    return [...selectedSchedule.value.workingDays].sort((a, b) => {
        return (dayOrder[a.day.name] || 8) - (dayOrder[b.day.name] || 8);
    });
});

const parseTime = (time) => {
    const today = dayjs.utc().format('YYYY-MM-DD');
    const utcDatetime = dayjs.utc(`${today} ${time}`, 'YYYY-MM-DD HH:mm');
    const converted = utcDatetime.tz(props.clinic.timezone);

    return converted.format('HH:mm')
}

//all days
const singleDoctorAllDaysDialog = ref(false)
const getAllDays = async () => {
    singleDoctorAllDaysDialog.value = true
}

const singleDoctorAllDaySaved = async (userId) => {
    singleDoctorAllDaysDialog.value = false

    await getSchedule()
    selectedSchedule.value = schedule.value.find(schedule => schedule.userId === userId)
}

const singleDoctorSingleDaySaved = async (userId) => {
    editingWorkingDayDialog.value = false

    await getSchedule()
    selectedSchedule.value = schedule.value.find(schedule => schedule.userId === userId)
}

const editingWorkingDay = ref(null)
const editingWorkingDayDialog = ref(false)

const selectWorkingDay = workingDay => {
    editingWorkingDay.value = { ...workingDay }
    editingWorkingDayDialog.value = true
}

const updatingTreatmentTypes = ref(false)
const updateTreatmentTypes = async () => {

    updatingTreatmentTypes.value = true
    const setDoctorClinicAppointmentTreatmentTypesFromCalendarForScrapper = httpsCallable(functions, 'setDoctorClinicAppointmentTreatmentTypesFromCalendarForScrapper')

    await setDoctorClinicAppointmentTreatmentTypesFromCalendarForScrapper({
        ids: selectedDoctorAppointmentDurations.value,
        doctorId: selectedSchedule.value.userId
    })

    await getSchedule()
    selectedSchedule.value = schedule.value.find(schedule => schedule.userId === selectedSchedule.value.userId)
    selectedDoctorAppointmentDurations.value = selectedSchedule.value.appointmentDurations.length > 0 ? selectedSchedule.value.appointmentDurations.map(item => item.clinicAppointmentDurationId) : []

    updatingTreatmentTypes.value = false
}

</script>

<style scoped>
.dayHover {
    color: lightblue;
    text-decoration: underline;
    cursor: pointer;
}
</style>