<template>
    <v-card>
        <v-card-title>
            {{ props.selectedSchedule.name }}
        </v-card-title>
        <v-card-text>
            <div class="d-flex">
                <div class="d-flex flex-column">
                    <v-checkbox v-model="selectedDays" hide-details variant="compact" v-for="day in props.days"
                        :key="day.id" :label="day.name" :value="day.id"></v-checkbox>
                </div>

                <div class="d-flex flex-column ml-12">
                    <p>from</p>

                    <div class="d-flex align-center mt-2">
                        <v-select width="150" label="Hour" v-model="fromHour" :items="allHours"></v-select>
                        <v-select width="150" label="Minute" v-model="fromMinute" :items="allMinutes"></v-select>
                    </div>
                    <p>to</p>
                    <div class="d-flex align-center mt-2">
                        <v-select width="150" label="Hour" v-model="toHour" :items="allHours"></v-select>
                        <v-select width="150" label="Minute" v-model="toMinute" :items="allMinutes"></v-select>
                    </div>

                    <v-btn :disabled="saving" @click="close()">cancel</v-btn>
                    <v-btn class="mt-3" :loading="saving" @click="save()">save</v-btn>
                </div>
            </div>
        </v-card-text>

        <v-snackbar v-model="validatonSnackbar" color="error" timeout="3000">
            At least one day and hours must be defined
        </v-snackbar>
    </v-card>
</template>

<script setup>
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref, defineProps, defineEmits } from 'vue';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'

const functions = getFunctions()

dayjs.extend(utc);
dayjs.extend(timezone);

const props = defineProps({
    selectedSchedule: {
        type: Object
    },
    days: {
        type: Object
    },
    timezone: {
        type: String
    }
})

const emit = defineEmits(['close', 'saved']);


const selectedDays = ref([])

const allHours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
const allMinutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));

const close = () => {
    emit('close')
}

// Logic
const fromHour = ref('')
const fromMinute = ref('')
const toHour = ref('')
const toMinute = ref('')

const validatonSnackbar = ref(false)
const saving = ref(false)

const save = async () => {
    if (!fromHour.value || !fromMinute.value || !toHour.value || !toMinute.value) {
        validatonSnackbar.value = true
        return
    }
    saving.value = true
    const from = fromHour.value + ':' + fromMinute.value + ':00'
    const to = toHour.value + ':' + toMinute.value + ':00'

    console.log('timez', from, to)

    console.log(dayjs().format('YYYY-MM-DD'))
    // const localMoment = moment.tz(dateTimeString, 'YYYY-MM-DD HH:mm', mytimeozne);

    const fromTime = dayjs.tz(`${dayjs().format('YYYY-MM-DD')} ${from}`, 'YYYY-MM-DD HH:mm:ss', props.timezone).utc().format('HH:mm:ss')
    const toTime = dayjs.tz(`${dayjs().format('YYYY-MM-DD')} ${to}`, 'YYYY-MM-DD HH:mm:ss', props.timezone).utc().format("HH:mm:ss")

    console.log(fromTime, toTime)


    const setDoctorFullScheduleFromCalendarForScrapper = httpsCallable(functions, 'setDoctorFullScheduleFromCalendarForScrapper')
    const result = await setDoctorFullScheduleFromCalendarForScrapper({
        userId: props.selectedSchedule.userId,
        from: fromTime,
        to: toTime,
        dayIds: selectedDays.value
    })

    console.log(result)
    saving.value = false
    emit('saved', props.selectedSchedule.userId)
}

</script>