import axios from 'axios';
import { getAuth } from 'firebase/auth';
const performOutboundCalls = async (selectedValues, clinicId) => {

    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
        ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
        : 'https://us-central1-mdhub-staging.cloudfunctions.net';

    const currentUser = getAuth().currentUser;
    if (!currentUser) {
        throw new Error('No authenticated user found');
    }

    // const token = await currentUser.getIdToken();
    console.log('selectedValues', selectedValues);
    await axios.post(
        `${baseUrl}/performOutboundCalls`,
        { patientNumberIds: selectedValues, clinicId },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            },
            withCredentials: true
        }
    );

}

export { performOutboundCalls };
