<template>
    <div class="ab-testing-maps">
        <v-btn variant="tonal" @click="openABTestingDialog" class="mb-4">
            A/B Testing Maps
        </v-btn>

        <v-dialog v-model="abTestingDialog" width="900">
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center pa-4">
                    A/B Testing Maps for {{ clinicName }}
                    <v-btn icon variant="text" @click="abTestingDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <h3 class="mb-4">A/B Testing Configuration</h3>

                    <v-alert type="info" class="mb-4">
                        <p><strong>How to use:</strong> You can insert these keys in the agent greeting using the format
                            <code>{{ key }}</code>
                        </p>
                        <p>For example, if you have a key named "greeting", you can use <code>{{ greeting }}</code> in
                            the agent greeting text.</p>
                        <p>The system will automatically substitute the appropriate value from Version A or B.</p>
                    </v-alert>

                    <v-alert v-if="!combinedKeys || combinedKeys.length === 0" type="info"
                        text="No A/B testing data exists yet. Add your first key-value pair below." class="mb-4">
                    </v-alert>

                    <!-- Agent Greeting Editor -->
                    <div class="mb-5">
                        <v-card variant="outlined" class="pa-4">
                            <h4 class="mb-3">Agent Greeting</h4>
                            <v-textarea v-model="agentGreeting" label="Agent Greeting"
                                hint="Use {{ key }} to insert A/B testing variables" persistent-hint auto-grow rows="3"
                                class="mb-3"></v-textarea>

                            <div class="d-flex justify-space-between align-center">
                                <div class="text-caption text-grey">
                                    Changes to the greeting will be saved when you click "Save Changes"
                                </div>
                                <v-btn variant="tonal" color="primary" size="small" @click="saveGreetingOnly"
                                    :loading="savingGreeting" :disabled="!agentGreeting">
                                    Save Greeting Only
                                </v-btn>
                            </div>
                        </v-card>
                    </div>

                    <!-- Preview section -->
                    <div v-if="combinedKeys.length > 0" class="mb-4">
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-title>
                                    <div class="d-flex align-center">
                                        <v-icon class="mr-2">mdi-eye</v-icon>
                                        <span>Preview Greeting</span>
                                    </div>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <div class="d-flex">
                                        <div class="flex-grow-1 mr-4">
                                            <h4 class="mb-2">Version A Preview:</h4>
                                            <v-card variant="outlined" class="pa-3">
                                                {{ previewVersionA }}
                                            </v-card>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h4 class="mb-2">Version B Preview:</h4>
                                            <v-card variant="outlined" class="pa-3">
                                                {{ previewVersionB }}
                                            </v-card>
                                        </div>
                                    </div>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>

                    <!-- Table header -->
                    <div class="d-flex align-center mb-2 font-weight-bold">
                        <div class="flex-grow-1 mr-2">Key</div>
                        <div class="flex-grow-1 mr-2">Version A Value</div>
                        <div class="flex-grow-1 mr-2">Version B Value</div>
                        <div style="width: 48px"></div>
                    </div>

                    <!-- Existing key-value pairs -->
                    <div v-for="(key, index) in combinedKeys" :key="`combined-${index}`"
                        class="d-flex align-center mb-2">
                        <v-text-field v-model="combinedKeys[index]" label="Key" class="mr-2" :disabled="true"
                            hide-details></v-text-field>
                        <v-text-field v-model="combinedValuesA[index]" label="Version A Value" class="mr-2"
                            hide-details></v-text-field>
                        <v-text-field v-model="combinedValuesB[index]" label="Version B Value" class="mr-2"
                            hide-details></v-text-field>
                        <v-btn icon @click="removeItem(index)" color="error">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>

                    <!-- Add new key-value pair -->
                    <div class="d-flex align-center mt-4">
                        <v-text-field v-model="newKey" label="New Key" class="mr-2" hide-details></v-text-field>
                        <v-text-field v-model="newValueA" label="Version A Value" class="mr-2"
                            hide-details></v-text-field>
                        <v-text-field v-model="newValueB" label="Version B Value" class="mr-2"
                            hide-details></v-text-field>
                        <v-btn icon @click="addItem()" color="success" :disabled="!newKey">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>

                    <div class="d-flex justify-end mt-6">
                        <v-btn variant="tonal" color="primary" @click="saveChanges" :loading="saving">
                            Save All Changes
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref, watch, computed, defineProps, defineEmits } from 'vue';
import { db } from '@/firebase';

const props = defineProps({
    clinicId: {
        type: String,
        required: true
    },
    clinicName: {
        type: String,
        required: true
    }
});

const emit = defineEmits(['updated']);

const abTestingDialog = ref(false);
const saving = ref(false);
const savingGreeting = ref(false);

// Agent Greeting
const agentGreeting = ref('');
const originalGreeting = ref('');

// Version A and B data
const versionA = ref({});
const versionB = ref({});

// Combined data for the unified view
const combinedKeys = ref([]);
const combinedValuesA = ref([]);
const combinedValuesB = ref([]);

// New item fields
const newKey = ref('');
const newValueA = ref('');
const newValueB = ref('');

// Computed properties for previews
const previewVersionA = computed(() => {
    return processTemplate(agentGreeting.value, versionA.value);
});

const previewVersionB = computed(() => {
    return processTemplate(agentGreeting.value, versionB.value);
});

// Function to process template and substitute values
const processTemplate = (template, values) => {
    if (!template) return '';

    // Replace all instances of {{ key }} with the corresponding value
    let result = template;

    // Use regex to find all {{ key }} patterns
    const matches = template.match(/\{\{\s*([^}]+)\s*\}\}/g);

    if (matches) {
        matches.forEach(match => {
            // Extract the key from {{ key }}
            const key = match.replace(/\{\{\s*|\s*\}\}/g, '').trim();

            // Replace with the value if it exists, otherwise keep the template
            if (values && values[key] !== undefined) {
                result = result.replace(match, values[key]);
            } else {
                result = result.replace(match, `[No value for "${key}"]`);
            }
        });
    }

    return result;
};

// Fetch A/B testing data and agent greeting when dialog opens
const fetchABTestingData = async () => {
    try {
        const clinicDoc = await db.collection('clinics').doc(props.clinicId).get();
        const clinicData = clinicDoc.data();

        // Initialize version maps
        versionA.value = clinicData.versionA || {};
        versionB.value = clinicData.versionB || {};

        // Get agent greeting
        agentGreeting.value = clinicData.agentGreeting || '';
        originalGreeting.value = agentGreeting.value;

        // Update combined arrays
        updateCombinedArrays();
    } catch (error) {
        console.error('Error fetching A/B testing data:', error);
    }
};

// Update combined arrays from the maps
const updateCombinedArrays = () => {
    // Get all unique keys from both versions
    const allKeys = new Set([
        ...Object.keys(versionA.value),
        ...Object.keys(versionB.value)
    ]);

    combinedKeys.value = Array.from(allKeys);

    // Update values arrays
    combinedValuesA.value = combinedKeys.value.map(key =>
        versionA.value[key] !== undefined ? versionA.value[key] : '');

    combinedValuesB.value = combinedKeys.value.map(key =>
        versionB.value[key] !== undefined ? versionB.value[key] : '');
};

// Add a new item
const addItem = () => {
    if (newKey.value) {
        // Add to combined arrays
        combinedKeys.value.push(newKey.value);
        combinedValuesA.value.push(newValueA.value);
        combinedValuesB.value.push(newValueB.value);

        // Update version maps
        versionA.value = {
            ...versionA.value,
            [newKey.value]: newValueA.value
        };

        versionB.value = {
            ...versionB.value,
            [newKey.value]: newValueB.value
        };

        // Reset input fields
        newKey.value = '';
        newValueA.value = '';
        newValueB.value = '';
    }
};

// Remove an item
const removeItem = (index) => {
    const key = combinedKeys.value[index];

    // Remove from version maps
    const newVersionA = { ...versionA.value };
    const newVersionB = { ...versionB.value };

    delete newVersionA[key];
    delete newVersionB[key];

    versionA.value = newVersionA;
    versionB.value = newVersionB;

    // Remove from combined arrays
    combinedKeys.value.splice(index, 1);
    combinedValuesA.value.splice(index, 1);
    combinedValuesB.value.splice(index, 1);
};

// Save only the agent greeting
const saveGreetingOnly = async () => {
    savingGreeting.value = true;

    try {
        await db.collection('clinics').doc(props.clinicId).update({
            agentGreeting: agentGreeting.value
        });

        // Update original greeting
        originalGreeting.value = agentGreeting.value;

        // Emit updated event
        emit('updated', {
            versionA: versionA.value,
            versionB: versionB.value,
            agentGreeting: agentGreeting.value
        });
    } catch (error) {
        console.error('Error saving agent greeting:', error);
    } finally {
        savingGreeting.value = false;
    }
};

// Save all changes to Firestore
const saveChanges = async () => {
    saving.value = true;

    try {
        // Update versionA and versionB with current values
        const updatedVersionA = {};
        const updatedVersionB = {};

        combinedKeys.value.forEach((key, index) => {
            updatedVersionA[key] = combinedValuesA.value[index];
            updatedVersionB[key] = combinedValuesB.value[index];
        });

        // Save to Firestore
        await db.collection('clinics').doc(props.clinicId).update({
            versionA: updatedVersionA,
            versionB: updatedVersionB,
            agentGreeting: agentGreeting.value
        });

        // Update local data
        versionA.value = updatedVersionA;
        versionB.value = updatedVersionB;
        originalGreeting.value = agentGreeting.value;

        // Emit updated event
        emit('updated', {
            versionA: updatedVersionA,
            versionB: updatedVersionB,
            agentGreeting: agentGreeting.value
        });

        // Close dialog
        abTestingDialog.value = false;
    } catch (error) {
        console.error('Error saving A/B testing data:', error);
    } finally {
        saving.value = false;
    }
};

// Open dialog and fetch data
const openABTestingDialog = async () => {
    abTestingDialog.value = true;
    await fetchABTestingData();
};

// Watch for changes in the maps and update combined arrays
watch([versionA, versionB], () => {
    updateCombinedArrays();
}, { deep: true });
</script>

<style scoped>
.ab-testing-maps {
    display: inline-block;
}
</style>