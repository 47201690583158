<template>
    <v-card>
        <v-card-title>
            {{ props.workingDay.day.name }}
        </v-card-title>
        <v-card-text>
            <div class="d-flex flex-column">
                <p>from</p>

                <div class="d-flex align-center mt-2">
                    <v-select width="100" label="Hour" v-model="fromHour" :items="allHours"></v-select>
                    <v-select width="100" label="Minute" v-model="fromMinute" :items="allMinutes"></v-select>
                </div>
                <p>to</p>
                <div class="d-flex align-center mt-2">
                    <v-select width="100" label="Hour" v-model="toHour" :items="allHours"></v-select>
                    <v-select width="100" label="Minute" v-model="toMinute" :items="allMinutes"></v-select>
                </div>

                <v-btn :disabled="saving" @click="close()">cancel</v-btn>
                <v-btn class="mt-3" :loading="saving" @click="save()">save</v-btn>

            </div>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref, defineProps, defineEmits, onMounted } from 'vue'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const functions = getFunctions()

dayjs.extend(utc);
dayjs.extend(timezone);

const props = defineProps({
    workingDay: {
        type: Object
    },
    timezone: {
        type: String
    }
})

const emit = defineEmits(['close', 'saved']);


const fromHour = ref('')
const fromMinute = ref('')
const toHour = ref('')
const toMinute = ref('')

const allHours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, '0'));
const allMinutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, '0'));

onMounted(() => {
    const serverFrom = props.workingDay.workingHours.from
    const utcfrom = dayjs.utc(`${dayjs.utc().format('YYYY-MM-DD')} ${serverFrom}`, 'YYYY-MM-DD HH:mmss').tz(props.timezone).format('HH:mm')

    fromHour.value = utcfrom.substr(0, 2)
    fromMinute.value = utcfrom.substr(3, 2)

    const serverTo = props.workingDay.workingHours.to
    const utcTo = dayjs.utc(`${dayjs.utc().format('YYYY-MM-DD')} ${serverTo}`, 'YYYY-MM-DD HH:mmss').tz(props.timezone).format('HH:mm')

    toHour.value = utcTo.substr(0, 2)
    toMinute.value = utcTo.substr(3, 2)
})

const close = () => {
    emit('close')
}

const validatonSnackbar = ref(false)
const saving = ref(false)

const save = async () => {
    // setDoctorSingleDayScheduleFromCalendarForScrapper

    if (!fromHour.value || !fromMinute.value || !toHour.value || !toMinute.value) {
        validatonSnackbar.value = true
        return
    }

    saving.value = true
    const from = fromHour.value + ':' + fromMinute.value + ':00'
    const to = toHour.value + ':' + toMinute.value + ':00'

    console.log('timez', from, to)

    console.log(dayjs().format('YYYY-MM-DD'))
    // const localMoment = moment.tz(dateTimeString, 'YYYY-MM-DD HH:mm', mytimeozne);

    const fromTime = dayjs.tz(`${dayjs().format('YYYY-MM-DD')} ${from}`, 'YYYY-MM-DD HH:mm:ss', props.timezone).utc().format('HH:mm:ss')
    const toTime = dayjs.tz(`${dayjs().format('YYYY-MM-DD')} ${to}`, 'YYYY-MM-DD HH:mm:ss', props.timezone).utc().format("HH:mm:ss")

    console.log(fromTime, toTime)


    const setDoctorSingleDayScheduleFromCalendarForScrapper = httpsCallable(functions, 'setDoctorSingleDayScheduleFromCalendarForScrapper')
    const result = await setDoctorSingleDayScheduleFromCalendarForScrapper({
        workingHoursPerDayId: props.workingDay.workingHours.id,
        from: fromTime,
        to: toTime,
    })

    console.log(result)
    saving.value = false
    emit('saved', props.workingDay.workingdayableId)
}
</script>