<template>
  <div class="clinics-view">
    <v-row>
      <div class="add-clinic mr-3">
        <CreateFunctionView />
        <!-- New component is required here -->
        <ABTestingMaps v-if="selectedClinic" :clinic-id="selectedClinic.id" :clinic-name="selectedClinic.name"
          @updated="handleABTestingUpdated" class="mr-4" />
        <v-dialog v-model="openClinicModal" transition="dialog-bottom-transition" fullscreen persistent>
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn class="text-none font-weight-regular" prepend-icon="mdi-hospital-building" text="Add Clinic"
              variant="tonal" v-bind="activatorProps"></v-btn>

          </template>

          <v-card prepend-icon="mdi-hospital-building" title="Clinic Details">
            <v-btn icon variant="text" position="absolute" location="top right" @click="closeWithoutSaving"
              class="close-btn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Name" required v-model="newClinic.name"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Address" v-model="newClinic.address"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Phone" v-model="newClinic.phoneNumbers"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Seat1s" v-model="newClinic.seats"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Admins" v-model="newClinic.admins"></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="Clinicians" v-model="newClinic.clinicians"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field hint="comma separated" v-model="newClinic.domains" label="Domains"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field label="Outgoing Calls Phone Number" v-model="newClinic.callFrom"></v-text-field>
                </v-col>

              </v-row>
              <v-row>
                <v-card prepend-icon="mdi-android" title="Agent" width="100%">
                  <v-card-text>
                    <v-row dense>
                      <v-col>
                        <v-checkbox label="Enable Agent" v-model="newClinic.agentEnabled"></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox label="Recording Enabled" v-model="newClinic.recordingEnabled"></v-checkbox>
                      </v-col>
                      <v-col>
                        <v-checkbox label="Outgoing Calls Enabled"
                          v-model="newClinic.outgoingCallsEnabled"></v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row dense>

                      <v-col cols="12" md="4" sm="6">
                        <v-checkbox label="Enable Holidays Mode" v-model="newClinic.holidays"></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <!-- Previous fields remain the same until forwardsToNumber -->
                      <v-col cols="12">

                        <h4 class="mb-2">Forward To Numbers</h4>
                        <v-row dense>
                          <v-col cols="12" md="6">
                            <v-text-field label="Working Hours Number"
                              v-model="newClinic.forwardsToNumber.workingHours"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field label="After Hours Number"
                              v-model="newClinic.forwardsToNumber.afterHours"></v-text-field>
                          </v-col>
                        </v-row>


                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-select label="Timezone" v-model="newClinic.timezone" :items="usTimezones" item-title="text"
                          item-value="value" required></v-select>
                      </v-col>

                    </v-row>

                    <v-row dense="true">
                      <v-col>
                        <v-textarea label="Agent Greeting" auto-grow="true" rows="2"
                          v-model="newClinic.agentGreeting"></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row dense="true">
                      <v-col>
                        <v-textarea label="Agent Greeting After Hours" auto-grow="true" rows="2"
                          v-model="newClinic.agentGreetingAfterHours"></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field label="Request Types" v-model="newClinic.requestTypes"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">

                        <v-select :list-props="{ maxWidth: '550px' }" v-model="newClinic.functionsEnabled"
                          :items="agentCallFunctions" item-title="name" :item-props="agentFunctionDescription"
                          item-value="name" label="Available Functions" multiple chips
                          hint="Select the agent functions to enable for this clinic" persistent-hint></v-select>

                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">

                        <v-select :list-props="{ maxWidth: '550px' }" v-model="newClinic.tasksEnabled"
                          :items="agentTaskFunctions" item-title="name" :item-props="agentFunctionDescription"
                          item-value="name" label="Available Tasks" multiple chips
                          hint="Select the agent tasks to enable for this clinic" persistent-hint></v-select>

                      </v-col>
                    </v-row>
                  </v-card-text>

                </v-card>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text="Close" variant="plain" @click="closeWithoutSaving"></v-btn>

              <v-btn color="primary" text="Save" variant="tonal" @click="closeAndSave"></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <!-- list of clinics -->
      <v-col :cols="isClinicsCollapsed ? 2 : 12" :md="isClinicsCollapsed ? 1 : 4" class="transition-width">
        <v-card>
          <v-card-title class="d-flex align-center justify-space-between">
            <span v-if="!isClinicsCollapsed">Clinics</span>
            <v-tooltip :text="isClinicsCollapsed ? 'Expand clinics list' : 'Collapse clinics list'" location="bottom">
              <template v-slot:activator="{ props }">
                <v-btn icon size="small" variant="text" @click="isClinicsCollapsed = !isClinicsCollapsed"
                  class="ml-auto" v-bind="props">
                  <v-icon>{{ isClinicsCollapsed ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </v-card-title>
          <v-table hover v-if="!isClinicsCollapsed">
            <tbody>
              <tr v-for="clinic in clinics" :key="clinic.id" @click="selectClinic(clinic)"
                :class="{ 'clinic-item': true, 'selected-row': selectedClinic?.id === clinic.id }">
                <td>{{ clinic.name }}</td>
              </tr>
            </tbody>
          </v-table>
          <div v-else class="collapsed-clinics">
            <div v-for="clinic in clinics" :key="clinic.id" @click="selectClinic(clinic)"
              :class="{ 'clinic-icon': true, 'selected-clinic-icon': selectedClinic?.id === clinic.id }">
              <v-tooltip :text="clinic.name" location="right">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" size="small">mdi-hospital-building</v-icon>
                </template>
              </v-tooltip>
            </div>
          </div>
        </v-card>
      </v-col>
      <!-- Clinic Details -->
      <v-col :cols="isClinicsCollapsed ? 10 : 12" :md="isClinicsCollapsed ? 11 : 8" class="transition-width">
        <v-card v-if="selectedClinic" class="clinic-details">
          <div class="clinic-details-title">
            <v-btn variant="tonal" v-bind="activatorProps" @click="editClinic" class="mr-2">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn variant="tonal" @click="isDetailsCollapsed = !isDetailsCollapsed" class="mr-2">
              <v-icon>{{ isDetailsCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-btn>
            <v-card-title>{{ selectedClinic.name }}</v-card-title>
          </div>

          <v-card-text v-if="!isDetailsCollapsed">
            <v-table>
              <tbody>

                <tr>
                  <td class="text-subtitle-2 font-weight-bold">ID</td>
                  <td>{{ selectedClinic.id }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Address</td>
                  <td>{{ selectedClinic.address }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Phone Numbers</td>
                  <td>{{ selectedClinic.phoneNumbers }}</td>
                </tr>

                <tr>

                  <td class="text-subtitle-2 font-weight-bold">Holidays Mode</td>
                  <td>
                    <v-chip :color="selectedClinic.holidays ? 'success' : 'error'" size="small">
                      {{ selectedClinic.holidays ? 'Enabled' : 'Disabled' }}
                    </v-chip>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Forward To Numbers</td>
                  <td>
                    <div>
                      <strong>Working Hours:</strong>
                      {{ selectedClinic.forwardsToNumber?.workingHours || 'None' }}
                    </div>
                    <div>
                      <strong>After Hours:</strong>
                      {{ selectedClinic.forwardsToNumber?.afterHours || 'None' }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Timezone</td>
                  <td>{{ selectedClinic.timezone }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Admins</td>
                  <td>
                    <v-chip-group>
                      <v-chip v-for="admin in selectedClinic.admins" :key="admin" size="small" color="primary">
                        {{ admin }}
                      </v-chip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Clinicians</td>
                  <td>
                    <v-chip-group>
                      <v-chip v-for="clinician in selectedClinic.clinicians" :key="clinician" size="small"
                        color="success">
                        {{ clinician }}
                      </v-chip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Domains</td>
                  <td>
                    <v-chip-group>
                      <v-chip v-for="domain in selectedClinic.domains" :key="domain" size="small" color="info">
                        {{ domain }}
                      </v-chip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Seats</td>
                  <td>{{ selectedClinic.seats }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Templates</td>
                  <td>
                    <v-chip-group>
                      <v-chip v-for="template in selectedClinic.templates" :key="template.name" size="small"
                        color="warning">
                        {{ template.name }}
                      </v-chip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Agent Enabled </td>
                  <td>{{ selectClinic.agentEnabled }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Agent ids </td>
                  <td>
                    <p v-for="agent in parseAgentIds(selectedClinic?.agentIds)" :key="agent.agentId">
                      agent id: <span style="font-weight: bold;"> {{ agent.agentId }}</span> | phone number: <span
                        style="font-weight: bold;">{{ agent.phoneNumber }} </span>
                    </p>
                    <v-btn variant="tonal" class="my-2" @click="openAgentIdsDialog()">edit</v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Calendar Enabled </td>
                  <td v-if="selectedClinic.calendarEnabled">
                    <v-btn variant="tonal" @click="getCalendarSettings" :loading="gettingCalendarSettings">Calendar
                      settings</v-btn>
                    <v-btn variant="tonal" class="ml-2" @click="getSchedule" :loading="gettingSchedule">Schedule</v-btn>
                    <v-btn variant="tonal" class="ml-2" @click="getAppointmentTypes">Clinic
                      Appointment Types</v-btn>
                  </td>
                  <td v-else>
                    <v-btn variant="tonal" @click="enableCalendar">enable calendar</v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Recording Enabled </td>
                  <td>{{ selectedClinic.recordingEnabled }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Outgoing Calls Enabled</td>
                  <td>
                    <div class="d-flex align-center">
                      <div class="mr-4">{{ selectedClinic.outgoingCallsEnabled }}</div>

                    </div>
                  </td>

                </tr>

                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Outgoing Calls Phone Number</td>
                  <td>{{ selectedClinic.callFrom }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Phone Numbers To Call</td>
                  <td>
                    <CallsStatusTable v-if="selectedClinic" :clinic-id="selectedClinic.id" />
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Agent Prompt </td>
                  <td>
                    <v-btn text @click="openPrompt = true">Open Agent Prompt</v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Agent Greeting </td>
                  <td>{{ selectedClinic.agentGreeting }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Agent Greeting After Hours </td>
                  <td>{{ selectedClinic.agentGreetingAfterHours }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold"> Request Types </td>
                  <td>{{ selectedClinic.requestTypes }}</td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Enabled Functions</td>
                  <td>
                    <v-chip-group>
                      <v-tooltip v-for="functionName in selectedClinic.functionsEnabled" :key="functionName"
                        :text="getFunctionDescription(functionName)">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" size="small" color="secondary">
                            {{ functionName }}
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Enabled Tasks</td>
                  <td>
                    <v-chip-group>
                      <v-tooltip v-for="taskName in selectedClinic.tasksEnabled" :key="taskName"
                        :text="getTasksDescription(taskName)">
                        <template v-slot:activator="{ props }">
                          <v-chip v-bind="props" size="small" color="secondary">
                            {{ taskName }}
                          </v-chip>
                        </template>
                      </v-tooltip>
                    </v-chip-group>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">Summary Prompt</td>
                  <td>
                    <v-btn text @click="openSummaryPromptDialog">Open Summary Prompt</v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="text-subtitle-2 font-weight-bold">A/B Testing Maps</td>
                  <td>
                    <div v-if="selectedClinic.versionA && Object.keys(selectedClinic.versionA).length > 0">
                      <strong>Version A:</strong>
                      <v-chip-group>
                        <v-chip v-for="(value, key) in selectedClinic.versionA" :key="`versionA-${key}`" size="small"
                          color="info">
                          {{ key }}: {{ value }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                    <div v-if="selectedClinic.versionB && Object.keys(selectedClinic.versionB).length > 0" class="mt-2">
                      <strong>Version B:</strong>
                      <v-chip-group>
                        <v-chip v-for="(value, key) in selectedClinic.versionB" :key="`versionB-${key}`" size="small"
                          color="warning">
                          {{ key }}: {{ value }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                    <div v-if="(!selectedClinic.versionA || Object.keys(selectedClinic.versionA).length === 0) &&
                      (!selectedClinic.versionB || Object.keys(selectedClinic.versionB).length === 0)">
                      No A/B testing maps configured
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-card-text>
          <v-card-text v-else class="text-center py-2">
            <v-icon icon="mdi-chevron-down" color="grey-lighten-1" />
            <span class="ml-2">Click to expand details</span>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text class="text-center pa-8">
            <v-icon icon="mdi-hospital-building" size="64" color="grey-lighten-1" />
            <div class="text-h6 mt-4">Select a clinic to view details</div>
          </v-card-text>
        </v-card>
        <ClinicConversations v-if="selectedClinic" :clinic-id="selectedClinic.id" class="mt-4" />
        <ClinicAgents v-if="selectedClinic" :selected-clinic="selectedClinic" />
      </v-col>
    </v-row>
  </div>
  <v-dialog v-model="openPrompt" transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-toolbar>
        <v-btn icon="mdi-close" @click="closePromptDialog"></v-btn>

        <v-toolbar-title prepend-icon="mdi-android">Agent Prompt</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn prepend-icon="mdi-pencil" text="Edit" variant="text" @click="isEditing = !isEditing"
            v-if="selectedVersion === 'current'"></v-btn>
          <v-spacer></v-spacer>
          <v-btn text="Rollback" color="warning" variant="tonal"
            :disabled="selectedVersion === 'current' || loadingAction" :loading="loadingAction"
            @click="handleRollback"></v-btn>
          <v-btn text="Save New Version" color="primary" variant="tonal" :loading="loadingAction"
            :disabled="!isEditing || selectedVersion !== 'current'" @click="handleSaveNewVersion"></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select v-model="selectedVersion" :items="promptVersions" item-title="label" item-value="versionId"
              label="Select Version" :loading="loadingVersions" @update:model-value="handleVersionChange"></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea style="overflow-y: auto;" auto-grow="true" :readonly="!isEditing" v-model="displayedPrompt"
              label="Agent Prompt"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="openSummaryPrompt" transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-toolbar>
        <v-btn icon="mdi-close" @click="openSummaryPrompt = false"></v-btn>

        <v-toolbar-title prepend-icon="mdi-android">Summary Prompt</v-toolbar-title>

        <v-spacer></v-spacer>

      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-textarea style="overflow-y: auto;" auto-grow="true" :readonly="!isEditing"
              v-model="selectedClinic.agentSummaryPrompt" label="Summary Prompt"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- TODO: Summary Prompt Dialog needs to be a new component! -->
  <v-dialog v-model="openSummaryPrompt" transition="dialog-bottom-transition" fullscreen>
    <v-card>
      <v-toolbar>
        <v-btn icon="mdi-close" @click="closeSummaryPromptDialog"></v-btn>
        <v-toolbar-title prepend-icon="mdi-android">Summary Prompt</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn prepend-icon="mdi-pencil" text="Edit" variant="text"
            @click="isEditingSummaryPrompt = !isEditingSummaryPrompt"
            v-if="selectedSummaryVersion === 'current'"></v-btn>
          <v-spacer></v-spacer>
          <v-btn text="Rollback" color="warning" variant="tonal"
            :disabled="selectedSummaryVersion === 'current' || loadingSummaryAction" :loading="loadingSummaryAction"
            @click="handleSummaryRollback"></v-btn>
          <v-btn text="Save New Version" color="primary" variant="tonal" :loading="loadingSummaryAction"
            :disabled="!isEditingSummaryPrompt || selectedSummaryVersion !== 'current'"
            @click="handleSaveSummaryVersion"></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select v-model="selectedSummaryVersion" :items="summaryPromptVersions" item-title="label"
              item-value="versionId" label="Select Version" :loading="loadingSummaryVersions"
              @update:model-value="handleSummaryVersionChange"></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea style="overflow-y: auto;" auto-grow="true" :readonly="!isEditingSummaryPrompt"
              v-model="displayedSummaryPrompt" label="Summary Prompt"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="enableCalendarDialog" width="900">
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center pa-4">
        Enable Calendar for {{ selectedClinic.name }}
      </v-card-title>
      <v-card-text>
        <v-switch label="Texts enabled" v-model="newCalendar.textsEnabled" color="green"></v-switch>
        <div class="mb-3">
          <h3 class="mb-2">Clinic EHR</h3>
          <v-select label="EHR System" v-model="selectedClinic.clinicEhr" :value="selectedClinic.clinicEhr"
            :items="['kareo', 'amd']" hint="This is mandatory for the calendar syncing" persistent-hint></v-select>
        </div>



        <h3>Put {date} and {time} to be parsed !!</h3>
        <div class="d-flex flex-column mt-3">
          <v-textarea v-model="newCalendar.scheduleText" label="Schedule text" persistent-hint
            hint="The first text the patient gets when the appointment is created" required
            :rules="[v => !!v || 'Schedule text is required']"></v-textarea>
          <v-textarea v-model="newCalendar.reminderText" class="mt-4" label="Reminder text" persistent-hint
            hint="The reminders' message text" required :rules="[v => !!v || 'Reminder text is required']"></v-textarea>

          <h4 class="mt-4">Reminder days before appointment:</h4>
          <v-row>
            <v-col cols="4">
              <v-checkbox variant="compact" hide-details v-model="newCalendar.reminderDays" label="1 days before"
                :value="1"></v-checkbox>
              <v-checkbox variant="compact" hide-details v-model="newCalendar.reminderDays" label="2 days before"
                :value="2"></v-checkbox>
              <v-checkbox variant="compact" hide-details v-model="newCalendar.reminderDays" label="3 days before"
                :value="3"></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox variant="compact" hide-details v-model="newCalendar.reminderDays" label="4 days before"
                :value="4"></v-checkbox>
              <v-checkbox variant="compact" hide-details v-model="newCalendar.reminderDays" label="5 days before"
                :value="5"></v-checkbox>
              <v-checkbox variant="compact" hide-details v-model="newCalendar.reminderDays" label="6 days before"
                :value="6"></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox variant="compact" hide-details v-model="newCalendar.reminderDays" label="7 days before"
                :value="7"></v-checkbox>
              <v-checkbox variant="compact" hide-details v-model="newCalendar.reminderDays" label="8 days before"
                :value="8"></v-checkbox>
              <v-checkbox variant="compact" hide-details v-model="newCalendar.reminderDays" label="9 days before"
                :value="9"></v-checkbox>
            </v-col>
          </v-row>

          <v-btn class="mt-5" variant="tonal" :loading="enablingCalendar" @click="enableCalendarSubmit"
            :disabled="!isCalendarFormValid">Update</v-btn>
        </div>
      </v-card-text>

    </v-card>
  </v-dialog>

  <v-dialog v-model="calendarSettingsDialog" width="900">
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center pa-4">
        Calendar settings for {{ selectedClinic.name }}
      </v-card-title>

      <v-card-text>
        <v-switch label="Texts enabled" v-model="calendarSettings.textsEnabled" color="green"></v-switch>

        <div class="mb-3">
          <h3 class="mb-2">Clinic EHR</h3>
          <v-select label="EHR System" v-model="selectedClinic.clinicEhr" :value="selectedClinic.clinicEhr"
            :items="['kareo', 'amd']" hint="This is mandatory for the calendar syncing" persistent-hint></v-select>
        </div>

        <h3>Put {date} and {time} to be parsed !!</h3>

        <v-textarea class="mt-3" v-model="calendarSettings.scheduleText" label="Schedule text" persistent-hint
          hint="The first text the patient gets when the appointment is created"></v-textarea>
        <v-textarea v-model="calendarSettings.reminderText" class="mt-4" label="Reminder text" persistent-hint
          hint="The reminders' message text"></v-textarea>

        <h4 class="mt-4">Reminder days before appointment:</h4>
        <v-row>
          <v-col cols="4">
            <v-checkbox variant="compact" hide-details v-model="calendarSettings.reminderDays" label="1 days before"
              :value="1"></v-checkbox>
            <v-checkbox variant="compact" hide-details v-model="calendarSettings.reminderDays" label="2 days before"
              :value="2"></v-checkbox>
            <v-checkbox variant="compact" hide-details v-model="calendarSettings.reminderDays" label="3 days before"
              :value="3"></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-checkbox variant="compact" hide-details v-model="calendarSettings.reminderDays" label="4 days before"
              :value="4"></v-checkbox>
            <v-checkbox variant="compact" hide-details v-model="calendarSettings.reminderDays" label="5 days before"
              :value="5"></v-checkbox>
            <v-checkbox variant="compact" hide-details v-model="calendarSettings.reminderDays" label="6 days before"
              :value="6"></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-checkbox variant="compact" hide-details v-model="calendarSettings.reminderDays" label="7 days before"
              :value="7"></v-checkbox>
            <v-checkbox variant="compact" hide-details v-model="calendarSettings.reminderDays" label="8 days before"
              :value="8"></v-checkbox>
            <v-checkbox variant="compact" hide-details v-model="calendarSettings.reminderDays" label="9 days before"
              :value="9"></v-checkbox>
          </v-col>
        </v-row>

        <v-btn class="mt-5" variant="tonal" :loading="updatingCalendar" @click="updateCalendar">Update</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="agentIdsDialog" width="900">
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center pa-4">
        Agent ids settings for {{ selectedClinic.name }}
      </v-card-title>
      <v-card-text class="d-flex flex-column">
        <v-row v-for="(agent, index) in agentIds" :key="index">
          <v-col cols="5">
            <v-text-field label="Phone number" v-model="agent.agentId"></v-text-field>
          </v-col>

          <v-col cols="5">
            <v-text-field label="Agent id" v-model="agent.phoneNumber"></v-text-field>
          </v-col>

          <v-col cols="2">
            <v-btn @click="agentIds.splice(index, 1)">delete</v-btn>
          </v-col>
        </v-row>
        <v-btn @click="agentIds.push({ phoneNumber: '', agentId: '' })">add</v-btn>
        <v-btn class="mt-3" variant="tonal" :loading="editingAgentIds" @click="editAgentIds()">Save</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="scheduleDialog" width="900">
    <ScheduleDialog :clinic="selectedClinic" />
  </v-dialog>

  <v-snackbar v-model="updatedSuccessfully" :timeout="2000" color="bittersweet">
    <span style="color:white;">Your changes have been successfully applied! {{ snackbarText }}</span>
  </v-snackbar>
  <AppointmentTypesDialog v-if="appointmentTypesDialog" v-model="appointmentTypesDialog" :clinic="selectedClinic" />
</template>

<script setup>
import ABTestingMaps from '@/components/ABTestingMaps.vue';
import CallsStatusTable from '@/components/CallsStatusTable.vue';
import ClinicAgents from '@/components/ClinicAgents.vue';
import ClinicConversations from '@/components/ClinicConversations.vue';
import CreateFunctionView from '@/components/CreateFunctionView.vue';
import AppointmentTypesDialog from "@/components/schedule/AppointmentTypesDialog.vue";
import ScheduleDialog from '@/components/schedule/ScheduleDialog.vue';
import { agentCallFunctions, agentFunctions, agentTaskFunctions, fetchAgentFunctions } from '@/composables/useAgentFunctions';
import { db } from '@/firebase';
import { sendEmail } from '@/functions/sendEmail';
import axios from 'axios'; // Make sure axios is installed and imported
import { getFunctions, httpsCallable } from "firebase/functions";
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';


const summaryPromptVersions = ref([]);
const selectedSummaryVersion = ref(null);
const loadingSummaryVersions = ref(false);
const displayedSummaryPrompt = ref('');
const isEditingSummaryPrompt = ref(false);
const loadingSummaryAction = ref(false);

const promptVersions = ref([]);
const selectedVersion = ref(null);
const loadingVersions = ref(false);
const displayedPrompt = ref('');

const isEditing = ref(false);
const loadingAction = ref(false);
const updatedSuccessfully = ref(false);
const snackbarText = ref('');
const clinics = ref([]);
const selectedClinic = ref(null);
const openClinicModal = ref(false);
const openPrompt = ref(false);
const openSummaryPrompt = ref(false);
const editing = ref(false);
const initialClinicians = ref([]);
const newClinic = ref({
  name: '',
  address: '',
  phoneNumbers: '',
  holidays: false,
  forwardsToNumber: {
    workingHours: '',
    afterHours: ''
  },
  timezone: '',
  seats: 0,
  admins: '',
  clinicians: '',
  domains: '',
  agentEnabled: false,
  recordingEnabled: false,
  outgoingCallsEnabled: false,
  callFrom: '',
  agentPrompt: '',
  agentGreeting: '',
  agentGreetingAfterHours: '',
  requestTypes: '',
  agentSummaryPrompt: '',
  functionsEnabled: [],
  tasksEnabled: [],
  versionA: {},
  versionB: {},
  clinicEhr: '',
});

const appointmentTypesDialog = ref(false);

const usTimezones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Adak',
  'America/Phoenix'
];

const getFunctionDescription = (name) => {
  const functionData = agentFunctions.value.find(f => f.name === name);
  return functionData ? functionData.description : '';
}

const getTasksDescription = (name) => {
  const functionData = agentFunctions.value.find(f => f.name === name);
  return functionData ? functionData.description : '';
}

const agentFunctionDescription = (item) => ({
  title: item.name,
  subtitle: item.description
})

const fetchClinics = async () => {
  try {
    const snapshot = await db.collection('clinics').get();

    const clinicsWithSubcollections = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const clinicData = {
          id: doc.id,
          ...doc.data()
        };
        const subcollectionSnapshot = await doc.ref.collection('agentSummaryPromptVersions').orderBy('dateCreated', 'desc').get();
        const agentSummaryPrompts = subcollectionSnapshot.docs.map(subdoc => ({
          id: subdoc.id,
          ...subdoc.data()
        }));

        return {
          ...clinicData,
          agentSummaryPrompts
        };
      })
    );

    // Sort clinics alphabetically by name
    clinics.value = clinicsWithSubcollections.sort((a, b) => {
      const nameA = (a.name || '').toLowerCase();
      const nameB = (b.name || '').toLowerCase();
      return nameA.localeCompare(nameB);
    });

  } catch (error) {
    console.error('Error fetching clinics:', error);
  }
};

const resetClinic = () => {
  newClinic.value = {
    name: '',
    address: '',
    phoneNumbers: '',
    holidays: false,
    forwardsToNumber: {
      workingHours: '',
      afterHours: ''
    },
    timezone: '',
    seats: 0,
    admins: '',
    clinicians: '',
    domains: '',
    outgoingCallsEnabled: false,
    callFrom: '',
    agentEnabled: false,
    recordingEnabled: false,
    agentPrompt: '',
    agentGreeting: '',
    agentGreetingAfterHours: '',
    requestTypes: '',
    agentSummaryPrompt: '',
    functionsEnabled: [],
    tasksEnabled: [],
    versionA: {},
    versionB: {},
    clinicEhr: ''
  };
}

const editClinic = () => {
  openClinicModal.value = true;
  editing.value = true;
};


const closeWithoutSaving = () => {
  openClinicModal.value = false;
  editing.value = false;
  openPrompt.value = false;
};

const closeAndSave = async () => {
  if (editing.value) {
    await updateClinic();
    console.log('clinic updated');
  } else {
    await saveClinic();
    console.log('clinic created');
  }
  openClinicModal.value = false;
  editing.value = false;
  updatedSuccessfully.value = true;
};

const handleRollback = async () => {
  if (selectedVersion.value === 'current') return;

  loadingAction.value = true;
  try {
    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
      ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
      : 'https://us-central1-mdhub-staging.cloudfunctions.net';
    await axios.post(
      `${baseUrl}/rollbackAgentPrompt`,
      {
        clinicId: selectedClinic.value.id,
        versionId: selectedVersion.value
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );

    // Refresh clinic data and versions
    await fetchClinics();
    const updatedClinic = clinics.value.find(c => c.id === selectedClinic.value.id);
    if (updatedClinic) {
      selectClinic(updatedClinic);
    }
    await fetchPromptVersions(selectedClinic.value.id);

  } catch (error) {
    console.error('Error rolling back version:', error);
  } finally {
    loadingAction.value = false;
  }
};

const handleSaveNewVersion = async () => {
  if (!isEditing.value || selectedVersion.value !== 'current') return;

  loadingAction.value = true;
  try {

    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
      ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
      : 'https://us-central1-mdhub-staging.cloudfunctions.net';
    await axios.post(
      `${baseUrl}/updateAgentPrompt`,
      {
        clinicId: selectedClinic.value.id,
        newPrompt: displayedPrompt.value
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );

    // Refresh clinic data and versions
    await fetchClinics();
    const updatedClinic = clinics.value.find(c => c.id === selectedClinic.value.id);
    if (updatedClinic) {
      selectClinic(updatedClinic);
    }
    await fetchPromptVersions(selectedClinic.value.id);

    // Reset editing state
    isEditing.value = false;
    updatedSuccessfully.value = true;
  } catch (error) {
    console.error('Error saving new version:', error);
  } finally {
    loadingAction.value = false;
  }
};


const saveClinic = async () => {
  try {
    const { phoneNumbers, admins, clinicians, domains, ...clinic } = newClinic.value;
    const phoneNumbersArray = phoneNumbers?.split(',');

    const forwardsToNumber = {
      workingHours: clinic.forwardsToNumber?.workingHours || '',
      afterHours: clinic.forwardsToNumber?.afterHours || ''
    };


    const adminsArray = admins.split(',');
    const cliniciansArray = clinicians.split(',');
    const domainsArray = domains.split(',');
    await db.collection('clinics').add({
      phoneNumbers: phoneNumbersArray,
      admins: adminsArray,
      forwardsToNumber,
      clinicians: cliniciansArray,
      timezone: clinic.timezone || '',
      domains: domainsArray,
      functionsEnabled: clinic.functionsEnabled || [],
      tasksEnabled: clinic.tasksEnabled || [],
      ...clinic
    });
    selectedClinic.value = {
      domains: domainsArray,
      phoneNumbers: phoneNumbersArray,
      admins: adminsArray,
      clinicians: cliniciansArray,
      forwardsToNumber,
      ...clinic
    }
    await sendWelcomeEmail(cliniciansArray);
    initialClinicians.value = cliniciansArray;
    await fetchClinics();
    resetClinic();
  } catch (error) {
    console.error('Error saving clinic:', error);
  }
};



const getArrayOfItems = (items) => {
  if (!items) return null
  return typeof items === 'string' ? items.split(',') : Object.values(items);
}

const updateClinic = async () => {
  try {

    const { phoneNumbers, admins, clinicians, domains, ...clinic } = newClinic.value;
    const phoneNumbersArray = getArrayOfItems(phoneNumbers);
    const forwardsToNumber = {
      workingHours: clinic.forwardsToNumber?.workingHours || '',
      afterHours: clinic.forwardsToNumber?.afterHours || ''
    };
    const adminsArray = getArrayOfItems(admins);
    const cliniciansArray = getArrayOfItems(clinicians);
    const domainsArray = getArrayOfItems(domains);

    await db.collection('clinics').doc(selectedClinic.value.id).update({
      phoneNumbers: phoneNumbersArray,
      admins: adminsArray,
      forwardsToNumber,
      timezone: clinic.timezone || '',
      clinicians: cliniciansArray,
      domains: domainsArray,
      functionsEnabled: clinic.functionsEnabled || [],
      tasksEnabled: clinic.tasksEnabled || [],
      ...clinic,
    });

    selectedClinic.value = {
      domains: domainsArray,
      phoneNumbers: phoneNumbersArray,
      admins: adminsArray,
      clinicians: cliniciansArray,
      forwardsToNumber,
      ...clinic
    }
    await fetchClinics();
    await sendWelcomeEmail(cliniciansArray);
    initialClinicians.value = cliniciansArray;
    resetClinic();
  } catch (error) {
    console.error('Error updating clinic:', error);
  }
};

const formatPrompt = (html) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  let text = tmp.textContent || tmp.innerText || '';
  text = text?.trim();
  return text;
}

let unsubscribe = null;

const listenToClinicChanges = () => {
  if (unsubscribe) {
    unsubscribe();
  }

  const ref = db.collection('clinics').doc(selectedClinic.value.id);
  unsubscribe = ref
    .onSnapshot((doc) => {
      if (doc.exists) {
        console.log('Clinic updated:', doc.data());
        selectedClinic.value = { id: doc.id, ...doc.data() };
      }
    });
};

watch(() => selectedClinic.value?.id, (newId, oldId) => {
  if (newId && newId !== oldId) {
    listenToClinicChanges();
  }
}, { immediate: true });

watch(() => editing.value, (isEditing) => {
  if (isEditing) {
    const { agentPrompt, ...clinic } = selectedClinic.value;
    console.log('clinic:', clinic);
    newClinic.value = {
      agentPrompt: (agentPrompt) ? formatPrompt(agentPrompt) : '',
      forwardsToNumber: {
        workingHours: clinic.forwardsToNumber?.workingHours || '',
        afterHours: clinic.forwardsToNumber?.afterHours || ''
      },
      versionA: clinic.versionA || {},
      versionB: clinic.versionB || {},
      ...clinic
    }
    return;
  }

  resetClinic();
});


const fetchSummaryPromptVersions = async (clinicId) => {
  loadingSummaryVersions.value = true;
  try {
    const versionsSnapshot = await db
      .collection('clinics')
      .doc(clinicId)
      .collection('agentSummaryPromptVersions')
      .orderBy('dateCreated', 'desc')
      .get();

    const versions = versionsSnapshot.docs.map(doc => ({
      versionId: doc.id,
      ...doc.data()
    }));

    summaryPromptVersions.value = [
      {
        versionId: 'current',
        label: `Current Version (${selectedClinic.value.version || 'N/A'})`,
        summaryPrompt: selectedClinic.value.agentSummaryPrompt || '',
        isCurrent: true,
        version: selectedClinic.value.version
      },
      ...versions.map(version => ({
        versionId: version.versionId,
        label: `Version ${version.version} - ${new Date(version.dateCreated).toLocaleDateString()}`,
        summaryPrompt: version.agentSummaryPrompt,
        isCurrent: false,
        version: version.version
      }))
    ];

    selectedSummaryVersion.value = 'current';
    displayedSummaryPrompt.value = selectedClinic.value.agentSummaryPrompt || '';
  } catch (error) {
    console.error('Error fetching summary prompt versions:', error);
  } finally {
    loadingSummaryVersions.value = false;
  }
};

const handleSummaryVersionChange = (versionId) => {
  const version = summaryPromptVersions.value.find(v => v.versionId === versionId);
  if (version) {
    displayedSummaryPrompt.value = version.summaryPrompt;
  }
};

const handleSaveSummaryVersion = async () => {
  if (!isEditingSummaryPrompt.value || selectedSummaryVersion.value !== 'current') return;

  loadingSummaryAction.value = true;
  try {
    const clinicRef = db.collection('clinics').doc(selectedClinic.value.id);
    const versionsRef = clinicRef.collection('agentSummaryPromptVersions');
    const currentVersion = selectedClinic.value.version || 0;
    const newVersion = currentVersion + 1;

    await versionsRef.add({
      version: newVersion,
      dateCreated: new Date().toISOString(),
      agentSummaryPrompt: displayedSummaryPrompt.value
    });

    await fetchClinics();
    const updatedClinic = clinics.value.find(c => c.id === selectedClinic.value.id);
    if (updatedClinic) {
      selectClinic(updatedClinic);
    }
    await fetchSummaryPromptVersions(selectedClinic.value.id);

    isEditingSummaryPrompt.value = false;
    updatedSuccessfully.value = true;
  } catch (error) {
    console.error('Error saving new summary version:', error);
  } finally {
    loadingSummaryAction.value = false;
  }
};

const handleSummaryRollback = async () => {
  if (selectedSummaryVersion.value === 'current') return;

  loadingSummaryAction.value = true;
  try {
    const version = summaryPromptVersions.value.find(v => v.versionId === selectedSummaryVersion.value);
    if (!version) return;
    console.log('rolling back to version:', version);
    const clinicRef = db.collection('clinics').doc(selectedClinic.value.id);

    await clinicRef.update({
      agentSummaryPrompt: version.summaryPrompt,
      version: version.version
    });

    await fetchClinics();
    const updatedClinic = clinics.value.find(c => c.id === selectedClinic.value.id);
    if (updatedClinic) {
      selectClinic(updatedClinic);
    }
    await fetchSummaryPromptVersions(selectedClinic.value.id);
    updatedSuccessfully.value = true;
  } catch (error) {
    console.error('Error rolling back summary version:', error);
  } finally {
    loadingSummaryAction.value = false;
  }
};

const openSummaryPromptDialog = async () => {
  if (selectedClinic.value) {
    openSummaryPrompt.value = true;
    await fetchSummaryPromptVersions(selectedClinic.value.id);
  }
};

const closeSummaryPromptDialog = () => {
  openSummaryPrompt.value = false;
  selectedSummaryVersion.value = null;
  summaryPromptVersions.value = [];
  displayedSummaryPrompt.value = '';
  isEditingSummaryPrompt.value = false;
};

const selectClinic = (clinic) => {
  let prompt = clinic.agentPrompt;
  if (prompt) {
    prompt = formatPrompt(prompt);
  }
  initialClinicians.value = clinic.clinicians;
  selectedClinic.value = {
    ...clinic,
    agentPrompt: prompt,
    agentSummaryPrompt: clinic.agentSummaryPrompt || '',
    version: clinic.version || 0,
    clinicEhr: clinic.clinicEhr
  };
  console.log('asdjkhaskfjsdf', selectedClinic.value)
};

watch(openSummaryPrompt, async (isOpen) => {
  if (isOpen && selectedClinic.value) {
    await fetchSummaryPromptVersions(selectedClinic.value.id);
  }
});

onMounted(async () => {
  await fetchClinics();
  await fetchAgentFunctions()
});

onUnmounted(() => {
  if (unsubscribe) {
    unsubscribe();
  }
});



// Function to fetch versions from the Firebase function
const fetchPromptVersions = async (clinicId) => {
  loadingVersions.value = true;
  try {
    const isProduction = process.env.NODE_ENV === 'production';
    const baseUrl = isProduction
      ? 'https://us-central1-nbox-6f560.cloudfunctions.net'
      : 'https://us-central1-mdhub-staging.cloudfunctions.net';
    const response = await axios.get(
      `${baseUrl}/getAgentPromptVersions?clinicId=${clinicId}`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    const versions = response.data.versions;

    promptVersions.value = [
      {
        versionId: 'current',
        label: `Current Version (${selectedClinic.value.versionNumber || 'N/A'})`,
        agentPrompt: selectedClinic.value.agentPrompt || '',
        isCurrent: true
      },
      ...versions.map(version => ({
        versionId: version.versionId,
        label: `Version ${version.versionNumber} - ${new Date(version.timestamp).toLocaleDateString()}`,
        agentPrompt: version.agentPrompt,
        isCurrent: false
      }))
    ];

    selectedVersion.value = 'current';
    displayedPrompt.value = selectedClinic.value.agentPrompt || '';
  } catch (error) {
    console.error('Error fetching prompt versions:', error);
  } finally {
    loadingVersions.value = false;
  }
};

// Handle version selection change
const handleVersionChange = (versionId) => {
  const version = promptVersions.value.find(v => v.versionId === versionId);
  if (version) {
    displayedPrompt.value = version.agentPrompt;
  }
};

const closePromptDialog = () => {
  openPrompt.value = false;
  selectedVersion.value = null;
  promptVersions.value = [];
  displayedPrompt.value = '';
  isEditing.value = false; // Reset editing state
};

// Update the existing watch for openPrompt
watch(openPrompt, async (isOpen) => {
  if (isOpen && selectedClinic.value) {
    await fetchPromptVersions(selectedClinic.value.id);
  }
});

const sendWelcomeEmail = async (updatedClinicians) => {

  const newClinicians = [];

  updatedClinicians.forEach((email) => {
    if (!initialClinicians.value.includes(email)) {
      newClinicians.push(email)
    }
  })

  console.log('newClinicians', newClinicians)
  const emailData = {
    from: 'js@mdhub.ai',
    to: newClinicians,
    subject: 'Welcome to mdhub.',
    clinicName: selectedClinic.value.name,
  }

  const res = await sendEmail(emailData)
  if (res.length > 0) {
    snackbarText.value = `Email sent to ${newClinicians.join(', ')}`
  }
}


// calendar
const enableCalendarDialog = ref(false);
const enableCalendar = () => {
  enableCalendarDialog.value = true;
  // Set default values for new calendar
  newCalendar.value = {
    textsEnabled: true,
    scheduleText: '',
    reminderText: '',
    reminderDays: []
  };
}


const enablingCalendar = ref(false);
const newCalendar = ref({
  textsEnabled: true,
  scheduleText: '',
  reminderText: '',
  reminderDays: []
});

// Add computed property to check form validity
const isCalendarFormValid = computed(() => {
  return (!!newCalendar.value.scheduleText && !!newCalendar.value.reminderText) || selectedClinic.value.clinicEhr;
});

const enableCalendarSubmit = async () => {

  // Validate required fields before submitting
  if (!isCalendarFormValid.value) {
    return;
  }

  // we can sync without the the rest of the calendar stuff 
  if ((!newCalendar.value.scheduleText && !newCalendar.value.reminderText) && selectedClinic.value.clinicEhr) {
    console.log('selectedClinic.clinicEhr', selectedClinic.value.clinicEhr)
    await db.collection('clinics').doc(selectedClinic.value.id).update({
      clinicEhr: selectedClinic.value.clinicEhr
    });
    updatedSuccessfully.value = true;
    snackbarText.value = 'Calendar settings enabled successfully!';
    enablingCalendar.value = false;
    enableCalendarDialog.value = false;
    return;
  }

  enablingCalendar.value = true;

  const functions = getFunctions();
  const enableClinicForCalendarForScrapper = httpsCallable(functions, 'enableClinicForCalendarForScrapper');

  try {
    await enableClinicForCalendarForScrapper({
      clinicId: selectedClinic.value.id,
      clinicName: selectedClinic.value.name,
      textsEnabled: newCalendar.value.textsEnabled,
      scheduleText: newCalendar.value.scheduleText,
      reminderText: newCalendar.value.reminderText,
      reminderDays: newCalendar.value.reminderDays,
      timezone: selectedClinic.value.timezone
    });

    // Update UI after successful operation
    selectedClinic.value.calendarEnabled = true;
    updatedSuccessfully.value = true;
    snackbarText.value = 'Calendar settings enabled successfully!';

  } catch (err) {
    console.error('Error enabling calendar:', err);
    // Show error in snackbar
    updatedSuccessfully.value = true;
    snackbarText.value = 'Error enabling calendar: ' + (err.message || 'Please check the form fields');
  } finally {
    enablingCalendar.value = false;
    enableCalendarDialog.value = false;
  }
}

const gettingCalendarSettings = ref(false);
const calendarSettings = ref({})
const calendarSettingsDialog = ref(false);

const getCalendarSettings = async () => {
  gettingCalendarSettings.value = true;

  const functions = getFunctions();
  const getClinicFromCalendarForScrapper = httpsCallable(functions, 'getClinicFromCalendarForScrapper');

  try {
    const { data } = await getClinicFromCalendarForScrapper({
      clinicId: selectedClinic.value.id
    })

    calendarSettings.value = data.data
    calendarSettingsDialog.value = true;

  } catch (err) {
    console.error('Error getting calendar settings:', err);
  }
  finally {
    gettingCalendarSettings.value = false;
  }
}

const updatingCalendar = ref(false);
const updateCalendar = async () => {
  updatingCalendar.value = true

  const functions = getFunctions()
  const updateClinicFromCalendarForScrapper = httpsCallable(functions, 'updateClinicFromCalendarForScrapper')

  try {
    await updateClinicFromCalendarForScrapper({
      clinicId: selectedClinic.value.id,
      clinicName: selectedClinic.value.name,
      textsEnabled: calendarSettings.value.textsEnabled,
      scheduleText: calendarSettings.value.scheduleText,
      reminderText: calendarSettings.value.reminderText,
      reminderDays: calendarSettings.value.reminderDays,
      timezone: selectedClinic.value.timezone
    })
    console.log('selectedClinic.clinicEhr', selectedClinic.value.clinicEhr)
    await db.collection('clinics').doc(selectedClinic.value.id).update({
      clinicEhr: selectedClinic.value.clinicEhr
    });
  } catch (err) {
    console.error('Error updating calendar settings:', err);
  } finally {
    updatingCalendar.value = false;
    calendarSettingsDialog.value = false;
  }
}

//agent ids
const parseAgentIds = (ids) => {
  if (!ids) return []
  return Object.entries(ids).map(([key, value]) => ({ agentId: key, phoneNumber: value }))
}

const agentIdsDialog = ref(false);
const agentIds = ref([])

const openAgentIdsDialog = () => {
  agentIdsDialog.value = true;

  agentIds.value = parseAgentIds(selectedClinic.value.agentIds)
}

const editingAgentIds = ref(false);
const editAgentIds = async () => {


  editingAgentIds.value = true;
  const agentIdsObject = agentIds.value.reduce((acc, curr) => {
    if (curr.agentId && curr.phoneNumber) {
      acc[curr.agentId] = curr.phoneNumber
    }
    return acc
  }, {})

  await db.collection('clinics').doc(selectedClinic.value.id).update({
    agentIds: agentIdsObject
  });
  editingAgentIds.value = false;
  agentIdsDialog.value = false;
}

const isDetailsCollapsed = ref(false);
const isClinicsCollapsed = ref(false);

// Handler for A/B testing maps updates
const handleABTestingUpdated = (data) => {
  if (selectedClinic.value) {
    selectedClinic.value.versionA = data.versionA;
    selectedClinic.value.versionB = data.versionB;

    // Update agent greeting if it was changed
    if (data.agentGreeting !== undefined) {
      selectedClinic.value.agentGreeting = data.agentGreeting;
    }

    updatedSuccessfully.value = true;
    snackbarText.value = 'A/B Testing maps updated successfully!';
  }
};

const gettingSchedule = ref(false)
const scheduleDialog = ref(false)

const getSchedule = () => {
  scheduleDialog.value = true
}

const getAppointmentTypes = () => {
  appointmentTypesDialog.value = true
}
</script>

<style scoped>
.clinics-view {
  padding: 16px;
}

.selected-row {
  background-color: rgb(var(--v-theme-primary-lighten-5));
}

.clinic-details :deep(td) {
  height: auto;
  padding: 12px;
}

.clinic-details :deep(.v-table) {
  background: transparent;
}

.clinic-details :deep(.v-chip-group) {
  margin: 0;
}

td {
  text-align: left;
}

.clinic-item {
  cursor: pointer;
}

.add-clinic {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.clinic-details-title {
  display: flex;
  justify-content: left;
  align-items: left;
}

.transition-width {
  transition: all 0.3s ease;
}

.collapsed-clinics {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
  max-height: 80vh;
  overflow-y: auto;
  margin: 0 auto;
}

.clinic-icon {
  margin: 8px 0;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #b2b7bc;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.clinic-icon:hover {
  background-color: #e0e0e0;
  transform: scale(1.1);
}

.selected-clinic-icon {
  background-color: rgb(var(--v-theme-primary-lighten-5));
  border: 2px solid rgb(var(--v-theme-primary));
}

.close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
</style>